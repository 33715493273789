import * as React from 'react';
import { useState, useEffect } from 'react';
import { CircularProgress, Tooltip, Button, Fade, Menu, MenuItem, IconButton } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import GroupsIcon from '@mui/icons-material/Groups';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import xlsx from 'xlsx';
import { saveAs } from 'file-saver';
import { fetchData } from '../../../utils/fetchMetrix';

const API_URL = process.env.REACT_APP_URL;

interface JFrogData {
    repoKey: string;
    repoType: string;
    foldersCount: number;
    filesCount: number;
    usedSpace: number;
    usedSpaceInBytes: number;
    itemsCount: number;
    packageType: string;
    projectKey: string;
    percentage: number;
}

export const DownloadSummary = (props: any) => {
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const [githubReposData, setGithubReposData] = useState<any[]>([]);
    const [codehubReposData, setCodehubReposData] = useState<any[]>([]);
    const [codehubLowerEnvReposData, setCodehubLowerEnvReposData] = useState<any[]>([]);
    const [quickstartReposData, setQuickstartReposData] = useState<any[]>([]);
    const [notLinkedReposData, setNotLinkedReposData] = useState<any[]>([]);
    const [teamsData, setTeamsData] = useState<any[]>([]);
    const [timeStamp, setTimeStamp] = useState(null);

    const [binariesCount, setBinariesCount] = useState(null);
    const [binariesSize, setBinariesSize] = useState(null);
    const [artifactsSize, setArtifactsSize] = useState(null);
    const [optimization, setOptimization] = useState(null);
    const [itemsCount, setItemsCount] = useState(null);
    const [artifactsCount, setArtifactsCount] = useState(null);

    const [Top10memory, setTop10memory] = useState<JFrogData[] | null>(null);

    const [codeHubInGB, setCodehubInGB] = useState(null);
    const [codehubCount, setCodehubCount] = useState(null);
    const [quickstartProdInGB, setQuickstartProdInGB] = useState(null);
    const [quickstartProdCount, setQuickstartProdCount] = useState(null);
    const [ipreadyToolsInGB, setIpreadyToolsInGB] = useState(null);
    const [ipreadyToolsCount, setIpreadyToolsCount] = useState(null);
    const [quickstartDevInGB, setQuickstartDevInGB] = useState(null);
    const [quickstartDevCount, setQuickstartDevCount] = useState(null);

    const [quickstartLocalInGB, setQuickstartLocalInGB] = useState(null);
    const [quickstartLocalCount, setQuickstarLocalCount] = useState(null);
    const [quickstartQAInGB, setQuickstartQAInGB] = useState(null);
    const [quickstartQACount, setQuickstartQACount] = useState(null);
    const [quickstartStageInGB, setQuickstartStageInGB] = useState(null);
    const [quickstartStageCount, setQuickstartStageCount] = useState(null);
    const [merisoftInGB, setMerisoftInGB] = useState(null);
    const [merisoftCount, setMerisoftCount] = useState(null);
    const [devopsInGB, setDevopsInGB] = useState(null);
    const [devopsCount, setDevopsCount] = useState(null);
    const [otherInGB, setOtherInGB] = useState(null);
    const [otherCount, setOtherCount] = useState(null);
    const [totalInGB, setTotalInGB] = useState(null);
    const [totalCount, setTotalCount] = useState(null);

    const fetchGithubData = async (doHardRefresh: boolean) => {
        const gitHubURL = `${API_URL}/api/v1/metric/github?refresh=${doHardRefresh}`;
        await fetchData(gitHubURL, (data: any) => {
            if (data) {
                setGithubReposData(data.githubRepoUrls);
                setCodehubReposData(data.codehubRepoUrls);
                setCodehubLowerEnvReposData(data.codehubLoweEnvRepoUrls);
                setQuickstartReposData(data.quickstartRepoUrls);
                setNotLinkedReposData(data.notLinkedReposUrls);
                setTeamsData(data.teamData);
                setTimeStamp(data.timestamp);
            }
        });
    };

    const fetchJfrogData = async (doHardRefresh: boolean) => {
        const jfrogURL = API_URL + `/api/v1/metric/jfrog?refresh=${doHardRefresh}`;

        await fetchData(jfrogURL, (data: any) => {
            if (data) {
                // First table
                setBinariesCount(data.binariesCount);
                setBinariesSize(data.binariesSize);
                setArtifactsSize(data.artifactsSize);
                setOptimization(data.optimization);
                setItemsCount(data.itemsCount);
                setArtifactsCount(data.artifactsCount);

                // Second table
                setTop10memory(data.top10MemoryUsed);

                // Third table
                setCodehubInGB(data.codehubJfrogSizeGB.toFixed(2));
                setCodehubCount(data.codehubJfrogCount);
                setQuickstartProdInGB(data.qsProdJfrogSizeGB.toFixed(2));
                setQuickstartProdCount(data.qsProdJfrogCount);
                setIpreadyToolsInGB(data.ipreadytoolsJfrogSizeGB.toFixed(2));
                setIpreadyToolsCount(data.ipreadytoolsJfrogCount);
                setQuickstartDevInGB(data.qsDevJfrogSizeGB.toFixed(2));
                setQuickstartDevCount(data.qsDevJfrogCount);
                setQuickstartLocalInGB(data.qsLocalJfrogSizeGB.toFixed(2));
                setQuickstarLocalCount(data.qsLocalJfrogCount);
                setQuickstartQAInGB(data.qsQaJfrogSizeGB.toFixed(2));
                setQuickstartQACount(data.qsQaJfrogCount);
                setQuickstartStageInGB(data.qsStageJfrogSizeGB.toFixed(2));
                setQuickstartStageCount(data.qsStageJfrogCount);
                setMerisoftInGB(data.meritsoftJfrogSizeGB.toFixed(2));
                setMerisoftCount(data.meritsoftJfrogCount);
                setDevopsInGB(data.devopsJfrogSizeGB.toFixed(2));
                setDevopsCount(data.devopsJfrogCount);
                setOtherInGB(data.otherJfrogSizeGB.toFixed(2));
                setOtherCount(data.otherJfrogCount);
                setTotalInGB(data.totalJfrogSize.toFixed(2));
                setTotalCount(data.totalJfrogCount);
            }
        });
    };
    useEffect(() => {
        fetchJfrogData(false);
    }, []);

    const jfrogCounts = [
        { name: 'Binaries Size', value: binariesSize },
        { name: 'Binaries Count ', value: binariesCount },
        { name: 'ArtifactsSize ', value: artifactsSize },
        { name: 'Optimization', value: optimization },
        { name: 'Item Count', value: itemsCount },
        { name: 'Artifact Count', value: artifactsCount },
    ];

    const jfrogSummaryOfDockerLocalCounts = [
        { dockerLocal: 'CodeHub', sumOfFileInGB: codeHubInGB, sumOfFile: codehubCount },
        { dockerLocal: 'Quickstart-Prod', sumOfFileInGB: quickstartProdInGB, sumOfFile: quickstartProdCount },
        { dockerLocal: 'IPReady Tools', sumOfFileInGB: ipreadyToolsInGB, sumOfFile: ipreadyToolsCount },
        { dockerLocal: 'Quickstart-Dev', sumOfFileInGB: quickstartDevInGB, sumOfFile: quickstartDevCount },
        { dockerLocal: 'Quickstart-QA', sumOfFileInGB: quickstartQAInGB, sumOfFile: quickstartQACount },
        { dockerLocal: 'Quickstart-Stage', sumOfFileInGB: quickstartStageInGB, sumOfFile: quickstartStageCount },
        { dockerLocal: 'Quickstart-Local', sumOfFileInGB: quickstartLocalInGB, sumOfFile: quickstartLocalCount },
        { dockerLocal: 'Meritsoft', sumOfFileInGB: merisoftInGB, sumOfFile: merisoftCount },
        { dockerLocal: 'Devops', sumOfFileInGB: devopsInGB, sumOfFile: devopsCount },
        { dockerLocal: 'Other', sumOfFileInGB: otherInGB, sumOfFile: otherCount },
        { dockerLocal: 'Total', sumOfFileInGB: totalInGB, sumOfFile: totalCount },
    ];

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTeams = async () => {
        try {
            setLoading(true);
            const teamsWorksheetData = teamsData.map((team: any) => [
                team.teamName,
                team.parentTeam?.name,
                team.memberCount,
                team.repositoryCount,
                team.createdAt,
            ]);

            const workbook = xlsx.utils.book_new();
            const teamsWorksheet = xlsx.utils.aoa_to_sheet([
                ['Team Name', 'Parent Name', 'Member Count', 'Repository Count', 'Created At'],
                ...teamsWorksheetData,
            ]);

            xlsx.utils.book_append_sheet(workbook, teamsWorksheet, 'Github Teams');

            const excelBuffer = xlsx.write(workbook, { type: 'array', bookType: 'xlsx' });

            const blob = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            const filename = `githubTeamsData_${timeStamp}.xlsx`;
            saveAs(blob, filename);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleDownload = async () => {
        try {
            setLoading(true);

            const githubWorksheetData = githubReposData.map((repo: any) => [
                repo.name,
                repo.size,
                repo.html_url,
                repo.created_at,
                repo.updated_at,
                repo.pushed_at,
                repo.visibility,
            ]);

            const codehubRepositories = githubReposData.filter((githubRepo) =>
                codehubReposData.some(
                    (chUrl) => chUrl.toLowerCase().trim() === githubRepo.html_url.toLowerCase().trim()
                )
            );

            const codehubWorksheetData = codehubRepositories.map((repo: any) => [
                repo.name,
                repo.size,
                repo.html_url,
                repo.created_at,
                repo.updated_at,
                repo.pushed_at,
                repo.visibility,
            ]);
            const quickstartRepositories = githubReposData.filter((githubRepo) =>
                quickstartReposData.some(
                    (qsUrl) => qsUrl.toLowerCase().trim() === githubRepo.html_url.toLowerCase().trim()
                )
            );

            const quickstartWorksheetData = quickstartRepositories.map((repo: any) => [
                repo.name,
                repo.size,
                repo.html_url,
                repo.created_at,
                repo.updated_at,
                repo.pushed_at,
                repo.visibility,
            ]);

            const codehubLowerEnvReposDataRepositories = githubReposData.filter((githubRepo) =>
                codehubLowerEnvReposData.some(
                    (leUrl) => leUrl.toLowerCase().trim() === githubRepo.html_url.toLowerCase().trim()
                )
            );

            const codehubLowerEnvWorksheetData = codehubLowerEnvReposDataRepositories.map((repo: any) => [
                repo.name,
                repo.size,
                repo.html_url,
                repo.created_at,
                repo.updated_at,
                repo.pushed_at,
                repo.visibility,
            ]);

            const notLinkedRepositories = githubReposData.filter((githubRepo) =>
                notLinkedReposData.some(
                    (nlurl) => nlurl.toLowerCase().trim() === githubRepo.html_url.toLowerCase().trim()
                )
            );

            const notLinkedWorksheetData = notLinkedRepositories.map((repo: any) => [
                repo.name,
                repo.size,
                repo.html_url,
                repo.created_at,
                repo.updated_at,
                repo.pushed_at,
                repo.visibility,
            ]);

            const workbook = xlsx.utils.book_new();
            const githubWorksheet = xlsx.utils.aoa_to_sheet([
                ['Name', 'Size', 'URL', 'Created At', 'Updated At', 'Pushed At', 'Visibility'],
                ...githubWorksheetData,
            ]);
            const codehubWorksheet = xlsx.utils.aoa_to_sheet([
                ['Name', 'Size', 'URL', 'Created At', 'Updated At', 'Pushed At', 'Visibility'],
                ...codehubWorksheetData,
            ]);
            const quickstartWorksheet = xlsx.utils.aoa_to_sheet([
                ['Name', 'Size', 'URL', 'Created At', 'Updated At', 'Pushed At', 'Visibility'],
                ...quickstartWorksheetData,
            ]);
            const codehubLowerEnvWorksheet = xlsx.utils.aoa_to_sheet([
                ['Name', 'Size', 'URL', 'Created At', 'Updated At', 'Pushed At', 'Visibility'],
                ...codehubLowerEnvWorksheetData,
            ]);
            const notLinkedWorksheet = xlsx.utils.aoa_to_sheet([
                ['Name', 'Size', 'URL', 'Created At', 'Updated At', 'Pushed At', 'Visibility'],
                ...notLinkedWorksheetData,
            ]);

            xlsx.utils.book_append_sheet(workbook, githubWorksheet, 'github Repositories');
            xlsx.utils.book_append_sheet(workbook, codehubWorksheet, 'codehub Repositories');
            xlsx.utils.book_append_sheet(workbook, quickstartWorksheet, 'quickstart Repositories');
            xlsx.utils.book_append_sheet(workbook, codehubLowerEnvWorksheet, 'codehub Lower Env Repositories');
            xlsx.utils.book_append_sheet(workbook, notLinkedWorksheet, 'not Linked Repositories');

            const excelBuffer = xlsx.write(workbook, { type: 'array', bookType: 'xlsx' });

            const blob = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            const filename = `githubReposData_${timeStamp}.xlsx`;
            saveAs(blob, filename);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    const handleJfrogData = async () => {
        try {
            setLoading(true);

            const jfrogLocalWorksheetData =
                Top10memory?.map((row: any) => [
                    row?.repoKey,
                    row?.repoType,
                    row?.foldersCount,
                    row?.filesCount,
                    row?.usedSpace,
                    row?.usedSpaceInBytes,
                    row?.itemsCount,
                    row?.packageType,
                    row?.projectKey,
                    row?.percentage,
                ]) || [];
            const jfrogUsageWorksheetData = jfrogCounts?.map((item) => [item.name, item.value]);
            const jfrogDockerWorksheetData = jfrogSummaryOfDockerLocalCounts?.map((item) => [
                item.dockerLocal,
                item.sumOfFileInGB,
                item.sumOfFile,
            ]);

            const workbook = xlsx.utils.book_new();
            const jfrogLocalWorksheet = xlsx.utils.aoa_to_sheet([
                [
                    'RepoKey',
                    'RepoType',
                    'Folders Count',
                    'Files Count',
                    'Used Space',
                    'Used space in bytes',
                    'Items Count',
                    'Package type',
                    'Project Key',
                    'Percentage',
                ],
                ...jfrogLocalWorksheetData,
            ]);
            const jfrogUsageWorksheet = xlsx.utils.aoa_to_sheet([['Name', 'Value'], ...jfrogUsageWorksheetData]);
            const jfrogDockerWorksheet = xlsx.utils.aoa_to_sheet([
                ['Summary of docker-local', 'Sum of Size(GB)', 'Sum Of Files'],
                ...jfrogDockerWorksheetData,
            ]);

            xlsx.utils.book_append_sheet(workbook, jfrogLocalWorksheet, 'JFrog Top10 LOCAL repository');
            xlsx.utils.book_append_sheet(workbook, jfrogUsageWorksheet, 'JFrog Overall Usage');
            xlsx.utils.book_append_sheet(workbook, jfrogDockerWorksheet, 'JFrog Docker Local Counts');

            const excelBuffer = xlsx.write(workbook, { type: 'array', bookType: 'xlsx' });

            const blob = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });

            const filename = `jfrogData_${timeStamp}.xlsx`;
            saveAs(blob, filename);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchGithubData(false);
    }, []);

    return (
        <>
            <Tooltip title="Download Summary">
                <Button
                    id="download-button"
                    aria-controls={open ? 'download-button' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    disabled={loading}
                    sx={{ backgroundColor: '#000048', color: '#fff', padding: '8px 12px' }}
                >
                    {loading ? (
                        <div style={{ display: 'flex', alignItems: 'center', marginRight: '8' }}>
                            <CircularProgress size={18} style={{ marginRight: 8 }} />
                            Please wait...
                        </div>
                    ) : (
                        <>Summary</>
                    )}
                    <ArrowDropDownIcon />
                </Button>
            </Tooltip>
            <Menu
                id="download-menu"
                MenuListProps={{
                    'aria-labelledby': 'download-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleDownload();
                    }}
                >
                    <IconButton>
                        <GitHubIcon style={{ color: '#000000' }} />
                    </IconButton>
                    GitHub Repositories
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleTeams();
                    }}
                >
                    <IconButton>
                        <GroupsIcon style={{ color: '#000000' }} />
                    </IconButton>
                    GitHub Teams
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleJfrogData();
                    }}
                >
                    <IconButton className="Jficon" />
                    JFrog
                </MenuItem>
            </Menu>
        </>
    );
};

export default DownloadSummary;
