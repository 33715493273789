import { Grid } from '@mui/material';

export const Footer = (props: any) => {
    const currentYear = new Date().getFullYear();
    return (
        <div className={props.className}>
            <Grid item xs={12} className="cognizant-footer">
                <span>
                    © {currentYear} Cognizant. All rights reserved. Cognizant Confidential and/or Trade Secret. Click
                    here for &nbsp;
                    <a href="https://www.cognizant.com/us/en/privacy-notice" target="_blank" rel="noopener noreferrer">
                        Cognizant's Privacy Statement.
                    </a>
                </span>
            </Grid>
        </div>
    );
};
