export const API_URL = process.env.REACT_APP_URL;
export const GITHUB_TEMPLATE_URL =
    'https://cognizantonline.sharepoint.com/:x:/r/sites/IPTools-IndiaCognizant-Kochi/Shared%20Documents/Kochi/GitHubTemplate.xlsx';
export const ENABLE_CIRCLECI_TEMPLATE_URL =
    'https://cognizantonline.sharepoint.com/:x:/r/sites/IPTools-IndiaCognizant-Kochi/Shared%20Documents/Kochi/EnableCircleCi_Template.xlsx';
export const GITHUB_ORGANIZATION_USER_URL = 'https://github.com/orgs/CognizantCodeHub/people';
export const GITHUB_ORGANIZATION_REPOSITORIES_URL = 'https://github.com/orgs/CognizantCodeHub/repositories';
export const GITHUB_ORGANIZATION_TEAMS_URL = 'https://github.com/orgs/CognizantCodeHub/teams';
export const GITLAB_ORGANIZATION_URL = 'https://gitlab.codehub.cognizantproducts.com/codehub';
export const GITHUB_JFROG_URL = 'https://codehub.jfrog.io/ui/login/';
