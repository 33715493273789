import Avatar from '@mui/material/Avatar';

function stringAvatar(name: string) {
    return {
        sx: {
            bgcolor: '#F1D00A',
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
}

export default function CustomAvatar({ user }: any) {
    return <Avatar {...stringAvatar(user.name)} />;
}
