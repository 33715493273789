import styled from 'styled-components';
import TableDialog from '../container/TableDialog';

export const TableDialogComponent = styled(TableDialog)<{}>`
    .scrollbar::-webkit-scrollbar {
        width: 0.4em;
        height: 0.4em;
    }

    .scrollbar::-webkit-scrollbar-track {
        webkit-box-shadow: inset 0 0 6px #d3dedc;
        border-radius: 0.2em;
    }

    .scrollbar::-webkit-scrollbar-thumb {
        background-color: rgb(18, 18, 18, 0.3);
        border-radius: 0.2em;
    }

    .scrollbar::-webkit-scrollbar-thumb:hover {
        background-color: rgb(18, 18, 18, 0.5);
    }
`;
