import { Suspense, useState } from 'react';
import { Grid } from '@mui/material';
import Header from './features/Header';
import Footer from './features/Footer';
import Loader from './features/Loader';
import Routes from './routes';
import { Response, responseContext } from './context';

function App() {
    const [pageState, setPageState] = useState<Response>({ loading: false, title: '', columnAndData: null });
    const serverResponseUpdater = (property: string, val: any) => {
        setPageState((prevState: Response) => ({ ...prevState, [property]: val }));
    };

    return (
        <div>
            <responseContext.Provider value={{ ...pageState, responseUpdater: serverResponseUpdater }}>
                <Header />
                <Grid item xs={12}>
                    <Suspense fallback={<Loader />}>
                        <Routes />
                    </Suspense>
                </Grid>
                <Footer />
            </responseContext.Provider>
        </div>
    );
}

export default App;
