import * as React from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import MenuItem from '@mui/material/MenuItem';
import { useHistory } from 'react-router-dom';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { Paper, Popover, Tooltip } from '@mui/material';
import { AccountCircle, Email, Logout } from '@mui/icons-material';
import GithubAction from '../Components/GithubAction';
import Template from '../Components/Template';
import DownloadSummary from '../Components/Summary';
import { getKeycloak } from '../../../utils/Keycloak';
import { useUserDetail } from '../../../hooks';
import CustomAvatar from '../Components/customAvatrar';
import cognizantLogo from '../../../assets/images/cognizantLogo.png';

export default function Header(props: any) {
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const user = useUserDetail();
    const history = useHistory();

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleReportClick = () => {
        history.push('/report');
        handleCloseUserMenu();
    };

    const logout = async () => {
        handleCloseNavMenu();
        const kc = await getKeycloak();
        kc.logout();
    };
    return (
        <AppBar position="static" style={{ backgroundColor: '#000048' }} className={props.className}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 1, display: { xs: 'none', md: 'flex' } }}
                        >
                            <img src={cognizantLogo} alt="Logo" height="60px" width="200px" />
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    ml: 1,
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    display: { xs: 'none', md: 'flex' },
                                    marginTop: '11px',
                                    marginLeft: '-5px',
                                }}
                            >
                                IP Ready Tools
                            </Typography>
                        </Typography>
                    </Link>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Popover
                            id="menu-appbar"
                            open={Boolean(anchorElNav)}
                            anchorEl={anchorElNav}
                            onClose={handleCloseNavMenu}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            <Paper>
                                <GithubAction close={handleCloseNavMenu} color={'black'} />
                                {/* <GitlabAction close={handleCloseNavMenu} color={'black'} /> This is Temporarily Disabled */}
                            </Paper>
                        </Popover>
                    </Box>
                    <Link to="/" style={{ color: 'white', textDecoration: 'none' }}>
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        >
                            <img src={cognizantLogo} alt="Logo" height="60px" width="200px" />
                            <Typography
                                variant="h6"
                                noWrap
                                sx={{
                                    ml: 0.5,
                                    flexGrow: 1,
                                    fontSize: '23px',
                                    fontWeight: '400',
                                    display: { xs: 'flex', md: 'none' },
                                    marginTop: '11px',
                                    marginLeft: '-5px',
                                }}
                            >
                                IPReadyTools
                            </Typography>
                        </Typography>
                    </Link>
                    <Link to="/sonarReport" style={{ color: 'white', textDecoration: 'none', marginLeft: '100px' }}>
                        <Typography>Sonar Report</Typography>
                    </Link>
                    <Link
                        to="/evaluationTracker"
                        style={{ color: 'white', textDecoration: 'none', marginLeft: '100px' }}
                    >
                        <Typography>Evaluation Tracker</Typography>
                    </Link>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' },
                            justifyContent: 'center',
                        }}
                    >
                        <Box>
                            <GithubAction color={'#ffffff'} />
                        </Box>
                        {/* <Box sx={{ paddingRight: '30%', paddingLeft: '5%' }}>
                            <GitlabAction color={'#ffffff'} /> 
                        </Box> This is Temporarily Disabled */}
                    </Box>
                    <Box sx={{ paddingRight: '1%' }}>
                        <Template color={'#ffffff'} />
                    </Box>
                    <Box sx={{ paddingRight: '1%' }}>
                        <DownloadSummary color={'#ffffff'} />
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <Tooltip title="Profile">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <CustomAvatar user={user} />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&:before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem key={'user_name'} onClick={handleCloseUserMenu} sx={{ color: '#000048' }}>
                                <IconButton sx={{ color: '#000048' }}>
                                    <AccountCircle />
                                </IconButton>
                                <Typography textAlign="center">{user.name}</Typography>
                            </MenuItem>
                            <MenuItem key={'user_email'} onClick={handleCloseUserMenu} sx={{ color: '#000048' }}>
                                <IconButton sx={{ color: '#000048' }}>
                                    <Email />
                                </IconButton>
                                <Typography textAlign="center">{user.email}</Typography>
                            </MenuItem>
                            <MenuItem key={'report'} onClick={handleReportClick} sx={{ color: '#000048' }}>
                                <IconButton sx={{ color: '#000048' }}>
                                    <AssessmentIcon />
                                </IconButton>
                                <Typography textAlign="center">Report</Typography>
                            </MenuItem>
                            <MenuItem key={'Logout'} onClick={logout} sx={{ color: '#000048' }}>
                                <IconButton sx={{ color: '#000048' }}>
                                    <Logout />
                                </IconButton>
                                <Typography textAlign="center">Logout</Typography>
                            </MenuItem>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
}
