import { useEffect, useState } from 'react';
import { Link, Grid, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { fetchData } from '../../../utils/fetchMetrix';
import RefreshMatrix from '../components/RefreshMatrix';
import GithubTeamCard from '../components/GithubTeamCard';
import '../StyledComponents/GithubTeamSummary.css';
const API_URL = process.env.REACT_APP_URL;

export const GithubTeamSummary = (props: any) => {
    const [ChildTeams, setChildTeams] = useState(null);
    const [WithoutRepo, setWithoutRepo] = useState(null);
    const [WithoutMembers, setWithoutMembers] = useState(null);

    const fetchGithubData = async (doHardRefresh: boolean) => {
        const gitHubURL = API_URL + `/api/v1/metric/github?refresh=${doHardRefresh}`;

        await fetchData(gitHubURL, (data: any) => {
            if (data) {
                setChildTeams(data.childTeamCount);
                setWithoutRepo(data.childTeamWithoutAnyRepo);
                setWithoutMembers(data.childTeamWithoutAnyMember);
            }
        });
    };

    const reinitializeData = async () => {
        setChildTeams(null);
        setWithoutRepo(null);
        setWithoutMembers(null);

        return Promise.all([/*fetchGitlabData(true),*/ fetchGithubData(true)]);
    };

    useEffect(() => {
        fetchGithubData(false);
    }, []);

    return (
        <>
            <Typography
                sx={{ position: 'absolute', top: '13%', left: '13%' }}
                color="text.secondary"
                title="Back to Dashboard"
            >
                <Link
                    underline="hover"
                    width={80}
                    sx={{
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px',
                        height: '40px',
                        cursor: 'pointer',
                        position: 'absolute',
                        backgroundColor: '#000048',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#2466AD',
                            color: '#000048',
                        },
                    }}
                    href="/"
                >
                    <ArrowBackIcon />
                </Link>
            </Typography>
            <div className={props.className}>
                <RefreshMatrix onRefresh={reinitializeData} />
                <Grid className="gridMainContainer" spacing={2}>
                    <Grid item container xs={12} spacing={2} className="subGrid">
                        <Grid item md={3}>
                            <GithubTeamCard type="Total Lowest Level Child Teams" value={ChildTeams} color="#fff" />
                        </Grid>
                        <Grid item md={3}>
                            <GithubTeamCard type="Child Teams Without Any Repos" value={WithoutRepo} color="#fff" />
                        </Grid>
                        <Grid item md={3}>
                            <GithubTeamCard
                                type="Child Teams Without Any Members"
                                value={WithoutMembers}
                                color="#fff"
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
