import { useState, useEffect } from 'react';
import { Grid, Link, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import '../StyledComponents/GithubRepoSummary.css';
import { fetchData } from '../../../utils/fetchMetrix';
import RefreshMatrix from '../components/RefreshMatrix';
import GithubCard from '../components/GithubCard';

const API_URL = process.env.REACT_APP_URL;

export const GithubRepoSummary = (props: any) => {
    const [githubQuickStartCount, setGithubQuickStartCount] = useState(null);
    const [repoNotLinkedandSizeZero, setRepoNotLinkedandSizeZero] = useState(null);
    const [totalCodehubCount, setTotalCodehubCount] = useState(null);

    const fetchGithubData = async (doHardRefresh: boolean) => {
        const gitHubURL = API_URL + `/api/v1/metric/github?refresh=${doHardRefresh}`;

        await fetchData(gitHubURL, (data: any) => {
            if (data) {
                setGithubQuickStartCount(data.quickstartTotalRepoCount);
                setRepoNotLinkedandSizeZero(data.notLinkedUrlCount);
                setTotalCodehubCount(data.chRepoCount);
            }
        });
    };

    const reinitializeData = async () => {
        setGithubQuickStartCount(null);
        setRepoNotLinkedandSizeZero(null);
        setTotalCodehubCount(null);

        return Promise.all([fetchGithubData(true)]);
    };

    useEffect(() => {
        fetchGithubData(false);
    }, []);

    return (
        <>
            <Typography
                sx={{ position: 'absolute', top: '13%', left: '13%' }}
                color="text.secondary"
                title="Back to Dashboard"
            >
                <Link
                    underline="hover"
                    width={80}
                    sx={{
                        borderRadius: '50%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '40px',
                        height: '40px',
                        cursor: 'pointer',
                        position: 'absolute',
                        backgroundColor: '#000048',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#2466AD',
                            color: '#000048',
                        },
                    }}
                    href="/"
                >
                    <ArrowBackIcon />
                </Link>
            </Typography>
            <div className={props.className}>
                <RefreshMatrix onRefresh={reinitializeData} />
                <Grid spacing={2} className="gridMainContainer">
                    <Grid item container xs={12} spacing={2} className="subGrid">
                        <Grid item md={3}>
                            <GithubCard type="CodeHub" value={totalCodehubCount} color="#ffffff" />
                        </Grid>
                        <Grid item md={3}>
                            <GithubCard type="QuickStart" value={githubQuickStartCount} color="#ffffff" />
                        </Grid>
                        <Grid item md={3}>
                            <GithubCard type="Not linked" value={repoNotLinkedandSizeZero} color="#ffffff" />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        </>
    );
};
