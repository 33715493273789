import { lazy } from 'react';
import { Switch } from 'react-router';
import { Route } from 'react-router-dom';
import { GithubRepoSummary } from '../features/Dashboard/Container/GithubRepoSummary';
import { GithubTeamSummary } from '../features/Dashboard/Container/GithubTeamSummary';
import NoLongerGithubUser from '../features/Dashboard/Container/NoLongerGithubUser';
import SonarReport from '../features/Dashboard/Container/SonarReport';
import EvaluationTracker from '../features/Dashboard/Container/EvaluationTracker';

const Dashboard = lazy(() => import('../features/Dashboard'));
const PageNotFound = lazy(() => import('../features/PageNotFound'));
const ResponsePage = lazy(() => import('../features/ResponsePage'));
const Report = lazy(() => import('../features/Report'));
// const GithubRepoSummary = lazy(() => import('../features/Dashboard/Container'));

const Routes = () => {
    return (
        <Switch>
            <Route path="/" exact component={Dashboard} />
            <Route path="/home" exact component={Dashboard} />
            <Route path="/response" exact component={ResponsePage} />
            <Route path="/report" exact component={Report} />
            <Route path="/githubRepoSummary" exact component={GithubRepoSummary} />
            <Route path="/githubTeamSummary" exact component={GithubTeamSummary} />
            <Route path="/sonarReport" exact component={SonarReport} />
            <Route path="/evaluationTracker" exact component={EvaluationTracker} />
            <Route path="/noLongerGithubUser" exact component={NoLongerGithubUser} />

            {/* add more routes over here */}
            <Route component={PageNotFound} path="*" />
        </Switch>
    );
};
export default Routes;
