import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import CustomTable from './CustomTable';

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}
function prepareColumn(data: any): string[] {
    const list: string[] = [];
    data.forEach((el: any) => {
        for (const key in el) if (!list.includes(key)) list.push(key);
    });
    return list;
}

export default function MultiTabs(props: any) {
    const theme = useTheme();
    theme.palette.secondary.main = '#FFFFFF';
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ bgcolor: 'background.paper', width: '100%' }}>
            <AppBar position="static" sx={{ backgroundColor: '#000062', boxShadow: 0 }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    {props.tabs.map((el: string, indx: number) => (
                        <Tab key={`tab-${indx}`} label={el} {...a11yProps(indx)} />
                    ))}
                </Tabs>
            </AppBar>
            <>
                {props.tabs.map((el: string, indx: number) => (
                    <TabPanel key={`tabpanel-${indx}`} value={value} index={indx} dir={theme.direction}>
                        <CustomTable
                            columns={prepareColumn(props.data[el])}
                            rows={props.data[el]}
                            className={props.className}
                        />
                    </TabPanel>
                ))}
            </>
        </Box>
    );
}
