import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import './index.css';
import { userDetailContext } from './context';
import { getKeycloak, setKeyCloakAuthToken } from './utils/Keycloak';

let user: any = null;

const render = (Component: any) => {
    ReactDOM.render(
        <BrowserRouter>
            <userDetailContext.Provider value={user}>
                <Component />
            </userDetailContext.Provider>
        </BrowserRouter>,
        document.getElementById('root')
    );
};

const run = async () => {
    const keycloak = await getKeycloak();
    const authenticate = keycloak
        ? await keycloak.init({
              onLoad: 'login-required',
              checkLoginIframe: false,
          })
        : '';

    if (authenticate) {
        setKeyCloakAuthToken(keycloak.token);
        user = await keycloak.loadUserInfo();
        render(App);
    } else {
        keycloak.login();
    }
    render(App);
};

run();
