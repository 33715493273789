import React from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import CachedIcon from '@mui/icons-material/Cached';
import { Tooltip } from '@mui/material';

export default function RefreshMatrix(props: any) {
    const [loading, setLoading] = React.useState(false);

    function toggleLoading() {
        setLoading((prev) => !prev);
    }
    const refreshPage = () => {
        toggleLoading();
        props.onRefresh().then(() => {
            toggleLoading();
        });
    };

    return (
        <>
            <Tooltip title="Refresh Dashboard">
                <LoadingButton
                    className="loadingBtn"
                    onClick={refreshPage}
                    loading={loading}
                    variant="contained"
                    sx={{
                        backgroundColor: '#000048',
                        position: 'absolute',
                        top: '13%',
                        right: '13%',
                        borderColor: '#28DF99',
                        '&:hover': { backgroundColor: '#000048' },
                        width: '40px',
                        height: '40px',
                        minWidth: '0px',
                        borderRadius: '50%',
                    }}
                >
                    <CachedIcon />
                </LoadingButton>
            </Tooltip>
        </>
    );
}
