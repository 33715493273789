import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { xlsxToJson } from './../../../utils/readExcelSheet';
import { API_URL } from '../../../utils/constants';
import { getRequestHeader } from './../../../utils/Keycloak';
import { useResponse } from '../../../hooks';
import { useUserDetail } from './../../../hooks/index';
import FileUploadButton from './FileUploadButton';
import TableDialog from './../../TableDialog';

const btnFill = { background: '#000062', borderRadius: 0, boxShadow: 'none', color: '#ffffff' };
const btnOutline = { borderColor: '#000062', borderRadius: 0, boxShadow: 'none', color: '#000062' };
const btnDisabled = { ...btnFill, background: '#adb5bd' };
interface CreateRepoTeam {
    Teams: [];
    Repos: [];
    Access: [];
}

export default function CreateGitHubRepoTeam(props: any) {
    const [excelUpload, setExcelUpload] = useState(false);
    const [data, setData] = useState<FormData>();
    const [jsonData, setJsonData] = useState<CreateRepoTeam>({ Teams: [], Repos: [], Access: [] });
    const [confirmCreate, setConfirmCreate] = useState(false);
    const pageResponse = useResponse();
    const user = useUserDetail();

    const handleClose = () => {
        props.onClose(false);
        resetstate();
    };
    const resetstate = () => {
        pageResponse?.responseUpdater('loading', false);
        setExcelUpload(false);
        setConfirmCreate(false);
    };

    const handleSubmit = () => {
        handleClose();
        pageResponse?.responseUpdater('loading', true);

        const header = getRequestHeader();
        fetch(API_URL + '/api/v1/upload', {
            body: data,
            method: 'POST',
            headers: header,
        })
            .then((response) => {
                return response.json();
            })
            .then((responseData) => {
                const resData = JSON.parse(JSON.stringify(responseData));
                pageResponse?.responseUpdater('title', 'Created GitHub Repository and Teams');
                pageResponse?.responseUpdater('columnAndData', {
                    Access: resData.response.access,
                    Teams: resData.response.teams,
                    Repos: resData.response.repos,
                });
                resetstate();
            })
            .catch(() => {
                resetstate();
            });
    };
    const handleConfirmDialog = (val: boolean) => {
        setConfirmCreate(val);
        setExcelUpload(false);
    };

    const mapColumnData = (map: [], sheet: string) => {
        setJsonData((prev: CreateRepoTeam) => ({
            ...prev,
            [sheet]: map,
        }));
    };

    const upload = (event: any) => {
        if (event.target.files.item(0) != null) {
            setExcelUpload(true);
            const data = new FormData();
            data.append('intake', event.target.files.item(0));
            data.append('createdBy', user.email);
            setData(data);

            xlsxToJson(data.get('intake'), 'Access', (access: any) => {
                mapColumnData(access, 'Access');
            });
            xlsxToJson(data.get('intake'), 'Teams', (teams: any) => {
                mapColumnData(teams, 'Teams');
            });
            xlsxToJson(data.get('intake'), 'Repos', (repos: any) => {
                mapColumnData(repos, 'Repos');
            });
        }
    };
    return (
        <>
            <Dialog open={props.createGitHubRepoTeam} aria-labelledby="responsive-dialog-title">
                <Box style={{ textAlign: 'right' }}>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <DialogTitle id="responsive-dialog-title">
                    <DialogContentText>Upload a File</DialogContentText>
                    <Typography style={{ fontSize: '1.7em' }}> {'Create Github Repo & Teams'} </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FileUploadButton style={btnFill} accept={['.xlsx']} onChange={(e: Event) => upload(e)} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ background: '#F1F1F1', padding: '2em' }}>
                    <Button autoFocus onClick={handleClose} variant="outlined" style={btnOutline}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        style={confirmCreate ? btnFill : btnDisabled}
                        disabled={!confirmCreate}
                    >
                        <Link to="/response" style={{ color: '#FFFFFF', textDecoration: 'none' }}>
                            Submit
                        </Link>
                    </Button>
                </DialogActions>
            </Dialog>
            <>
                <TableDialog
                    open={excelUpload}
                    onClose={resetstate}
                    onConfirm={handleConfirmDialog}
                    title={'Do you want to create the following repos & teams ?'}
                    columnAndData={jsonData}
                />
            </>
        </>
    );
}
