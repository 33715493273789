import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import MultiTabs from '../components/MultiTabs';

const btnOutline = { borderColor: '#000062', borderRadius: 0, boxShadow: 'none', color: '#000062' };
const btnFill = { background: '#000062', borderRadius: 0, boxShadow: 'none', color: '#ffffff' };
const btnDisabled = { ...btnFill, background: '#adb5bd' };

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function filterTabs(obj: any): string[] {
    const list: string[] = [];
    for (const key in obj) if (obj[key] !== null && obj[key].length > 0) list.push(key);
    return list;
}

function isTableDataPresent(tabs: string[], data: unknown[]): boolean {
    if (tabs != null && tabs.length > 0) {
        for (const tab of tabs) if (data[tab] != null && data[tab].length === 0) return false;
        return true;
    }
    return false;
}

export default function TableDialog(props: any) {
    const tabName = filterTabs(props.columnAndData);
    const checkFormedData = isTableDataPresent(tabName, props.columnAndData);
    const handleClose = () => {
        props.onClose();
    };
    const handleConfirm = (val: boolean) => {
        props.onConfirm(val);
    };
    return (
        <div className={props.className}>
            <Dialog
                open={props.open}
                TransitionComponent={Transition}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle style={{ fontWeight: 400, fontSize: '1.7em' }}>{props.title}</DialogTitle>
                <DialogContent>
                    <MultiTabs tabs={tabName} data={props.columnAndData} className={props.className} />
                </DialogContent>
                <DialogActions style={{ background: '#F1F1F1', padding: '2em' }}>
                    <Button autoFocus onClick={handleClose} variant="outlined" style={btnOutline}>
                        cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleConfirm(true);
                        }}
                        variant="contained"
                        style={checkFormedData ? btnFill : btnDisabled}
                        disabled={!checkFormedData}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
