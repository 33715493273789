import { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Close } from '@mui/icons-material';
import { xlsxToJson } from './../../../utils/readExcelSheet';
import { API_URL } from '../../../utils/constants';
import { getRequestHeader } from './../../../utils/Keycloak';
import { useResponse } from '../../../hooks';
import { useUserDetail } from './../../../hooks/index';
import FileUploadButton from './FileUploadButton';
import TableDialog from '../../TableDialog';

const btnFill = { background: '#000062', borderRadius: 0, boxShadow: 'none', color: '#ffffff' };
const btnOutline = { borderColor: '#000062', borderRadius: 0, boxShadow: 'none', color: '#000062' };
const btnDisabled = { ...btnFill, background: '#adb5bd' };
interface DeleteTeam {
    Teams: string[];
}

export default function DeleteGitHubTeam(props: any) {
    const [excelUpload, setExcelUpload] = useState(false);
    const [data, setData] = useState<FormData>();
    const [jsonData, setJsonData] = useState<DeleteTeam>({ Teams: [] });
    const [confirmCreate, setConfirmCreate] = useState(false);
    const pageResponse = useResponse();
    const user = useUserDetail();

    const handleClose = () => {
        props.onClose(false);
        resetstate();
    };

    const resetstate = () => {
        pageResponse?.responseUpdater('loading', false);
        setExcelUpload(false);
        setConfirmCreate(false);
    };

    const handleSubmit = () => {
        handleClose();
        pageResponse?.responseUpdater('loading', true);

        const header = getRequestHeader();
        fetch(API_URL + '/api/v1/upload/team', {
            body: data,
            method: 'DELETE',
            headers: header,
        })
            .then((response) => {
                return response.json();
            })
            .then((responseData) => {
                const resData = JSON.parse(JSON.stringify(responseData));
                pageResponse?.responseUpdater('title', 'Deleted GitHub Teams');
                pageResponse?.responseUpdater('columnAndData', { Teams: resData.response.teamsDeleted });

                resetstate();
            })
            .catch(() => {
                resetstate();
            });
    };

    const handleConfirmDialog = (val: boolean) => {
        setConfirmCreate(val);
        setExcelUpload(false);
    };

    const upload = (event: any) => {
        if (event.target.files.item(0) != null) {
            setExcelUpload(true);
            const formData = new FormData();
            formData.append('intake', event.target.files.item(0));
            formData.append('deletedBy', user.email);
            setData(formData);
            xlsxToJson(formData.get('intake'), 'Teams', (teams: any) => {
                setJsonData((prev: DeleteTeam) => ({ ...prev, Teams: teams }));
            });
        }
    };

    return (
        <>
            <Dialog open={props.deleteGitHubTeam} aria-labelledby="responsive-dialog-title">
                <Box style={{ textAlign: 'right' }}>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <DialogTitle id="responsive-dialog-title" style={{ padding: '1rem  8rem 1rem  2rem' }}>
                    <DialogContentText>Upload a File</DialogContentText>
                    <Typography style={{ fontSize: '1.7em' }}>Delete GitHub Team</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FileUploadButton style={btnFill} accept={['.xlsx']} onChange={(e: Event) => upload(e)} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ background: '#F1F1F1', padding: '2em' }}>
                    <Button autoFocus onClick={handleClose} variant="outlined" style={btnOutline}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        style={confirmCreate ? btnFill : btnDisabled}
                        disabled={!confirmCreate}
                    >
                        <Link to="/response" style={{ color: '#FFFFFF', textDecoration: 'none' }}>
                            Submit
                        </Link>
                    </Button>
                </DialogActions>
            </Dialog>
            <>
                <TableDialog
                    open={excelUpload}
                    onClose={resetstate}
                    onConfirm={handleConfirmDialog}
                    title={`Do you want to delete ${jsonData.Teams.length} GitHub Team ?`}
                    columnAndData={jsonData}
                />
            </>
        </>
    );
}
