import { useEffect, useState } from 'react';
import { Box, Button, TextField, Card, CardContent, Alert } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import xlsx from 'xlsx';
import { saveAs } from 'file-saver';
import { fetchData, postData } from '../../../utils/fetchMetrix';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import '../StyledComponents/DashboardCardGithub.css';

const API_URL = process.env.REACT_APP_URL;

interface UserData {
    samlId: string;
    user: string;
}

interface CachedData {
    validUsers: UserData[];
    invalidUsers: UserData[];
}

export const DownloadUsers = () => {
    const [token, setToken] = useState('');
    const [cachedData, setCachedData] = useState<CachedData | null>(null);
    const [excelReady, setExcelReady] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [timeStamp, setTimeStamp] = useState(null);

    useEffect(() => {
        const fetchCachedData = async () => {
            try {
                const cachedUrl = `${API_URL}/api/v1/cachedUsers/cachedUsers`;
                setIsLoading(true);
                await fetchData(cachedUrl, (cachedData: any) => {
                    if (cachedData && (cachedData.validUsers || cachedData.invalidUsers)) {
                        setCachedData(cachedData);
                        setExcelReady(true);
                        setTimeStamp(cachedData.timestamp);
                    }
                    setIsLoading(false);
                });
            } catch (error) {
                // Handle error
            }
        };

        fetchCachedData();
    }, []);

    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            setCachedData(null);
            if (token.length > 32) {
                const msgraphURL = `${API_URL}/api/v1/msgraphtoken/users`;

                await postData(msgraphURL, { token }, (data: any) => {
                    if (data && (data.validUsers || data.invalidUsers)) {
                        setCachedData(data);
                        setExcelReady(true);
                        setTimeStamp(data.timestamp);
                    } else {
                        setError('Please enter a valid Token');
                    }
                    setIsLoading(false);
                });
            } else {
                setError('Please enter a valid Token');
                return;
            }
        } catch (error) {
            // Handle error
        }
    };

    const handleUsers = () => {
        try {
            if (cachedData && excelReady) {
                const { validUsers, invalidUsers } = cachedData;
                const usersWithStatus = validUsers.map((user) => [
                    user.samlId,
                    user.user !== null ? user.user : 'null',
                    'Active',
                ]);
                invalidUsers.forEach((user) => {
                    usersWithStatus.push([user.samlId, user.user !== null ? user.user : 'null', 'Inactive']);
                });
                const workbook = xlsx.utils.book_new();
                const usersWorksheet = xlsx.utils.aoa_to_sheet([['Saml Id', 'User', 'Status'], ...usersWithStatus]);
                xlsx.utils.book_append_sheet(workbook, usersWorksheet, 'GitHub Users');
                const excelBuffer = xlsx.write(workbook, { type: 'array', bookType: 'xlsx' });
                const filename = `usersData.xlsx`;
                saveAs(
                    new Blob([excelBuffer], {
                        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }),
                    filename
                );
            } else {
                setError('Data is not Ready. Please submit valid token first');
            }
        } catch (error) {
            // Handle Error
        }
    };

    return (
        <div>
            <Snackbar
                open={isLoading}
                style={{ marginTop: '70px' }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <MuiAlert elevation={6} variant="filled" severity="info" onClose={() => setIsLoading(false)}>
                    Loading data...
                </MuiAlert>
            </Snackbar>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div style={{ maxWidth: '800px', margin: '100px 0', textAlign: 'center' }}>
                    <div>
                        <div style={{ display: 'flex', justifyContent: 'right' }}>
                            <div>Data Fetched on: &nbsp;</div>
                            <div className="timestamp" style={{ color: '#14c001' }}>
                                {timeStamp}
                            </div>
                        </div>

                        <h2 style={{ fontWeight: '1' }}>GitHub Users</h2>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                            <Card style={{ flex: '1', marginRight: '10px' }}>
                                <CardContent>
                                    <div
                                        style={{
                                            backgroundColor: '#000048',
                                            padding: '10px 10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: '#fff',
                                        }}
                                    >
                                        Valid Users
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                fontSize: '70px',
                                                fontWeight: '1',
                                                color: '#000048',
                                            }}
                                        >
                                            {cachedData && cachedData.validUsers.length !== 0 ? (
                                                cachedData.validUsers.length
                                            ) : (
                                                <Skeleton variant="text" width={120} height={100} />
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>

                            <Card style={{ flex: '1', marginRight: '10px' }}>
                                <CardContent>
                                    <div
                                        style={{
                                            backgroundColor: '#000048',
                                            padding: '10px 10px',
                                            display: 'flex',
                                            justifyContent: 'center',
                                            color: '#fff',
                                        }}
                                    >
                                        Invalid Users
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '5px' }}>
                                        <div
                                            style={{
                                                textAlign: 'center',
                                                fontSize: '70px',
                                                fontWeight: '1',
                                                color: '#000048',
                                            }}
                                        >
                                            {cachedData && cachedData.invalidUsers.length !== 0 ? (
                                                cachedData.invalidUsers.length
                                            ) : (
                                                <Skeleton variant="text" width={120} height={100} />
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            {excelReady && (
                                <Button
                                    onClick={handleUsers}
                                    variant="contained"
                                    color="success"
                                    sx={{ marginTop: '20px', width: '30%' }}
                                >
                                    Download Users Data
                                </Button>
                            )}
                        </Box>

                        <div style={{ marginBottom: '10px' }}>
                            <p style={{ marginTop: '20px', marginBottom: '10px', color: '#c3c2c2' }}>
                                To get the latest data, please submit a token below
                            </p>

                            <TextField
                                label="Enter MsGraph Token"
                                variant="outlined"
                                value={token}
                                onChange={(e) => {
                                    setToken(e.target.value);
                                    setError('');
                                }}
                                style={{ marginTop: '20px', width: '70%' }}
                            />
                            <Button
                                onClick={handleSubmit}
                                disabled={!token}
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: '20px', marginTop: '30px' }}
                            >
                                Submit
                            </Button>
                            {error && (
                                <Alert severity="error" style={{ marginTop: '10px' }}>
                                    {error}
                                </Alert>
                            )}
                            <p style={{ color: '#ff0000' }}>
                                Note: Follow the below instructions to get the access token
                            </p>
                            <ol style={{ textAlign: 'left', marginBottom: '10px' }}>
                                <li>
                                    The 'msgraph' access key is from here:{' '}
                                    <a target="blank" href="https://developer.microsoft.com/en-us/graph/graph-explorer">
                                        MS Graph Link
                                    </a>
                                    .
                                </li>
                                <li>
                                    Once you are in the graph explorer, click on the "person" icon in the top-right
                                    corner and login.
                                </li>
                                <li>
                                    Once you are logged in, click on the <b>"Access Token"</b> tab.
                                </li>
                                <li>Copy the access key into the "msgraph" key here.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadUsers;
