import styled from 'styled-components';
import { Footer } from '../Container/Footer';

export const FooterStyledComponent = styled(Footer)<{}>`
    bottom: 10px;
    width: 100%;

    .cognizant-footer {
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 16px;
        font-family: Roboto;
        font-size: 12px;
        letter-spacing: 0;
        line-height: 16px;
        transition: 0.2s;
        -webkit-transition: 0.2s;
        -moz-transition: 0.2s;
    }
  }
`;
