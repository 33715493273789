import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DatePicker from '@mui/lab/DatePicker';
import { format } from 'date-fns';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Select,
    MenuItem,
    SelectChangeEvent,
    tableCellClasses,
    Box,
    Button,
} from '@mui/material';

import { styled } from '@mui/material';
import axios from 'axios';
import { getRequestHeader } from '../../../utils/Keycloak';
import XLSX from 'xlsx';
import CircularProgress from '@mui/material/CircularProgress';
import '../StyledComponents/EvaluationTracker.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: 'rgb(224, 224, 224)',
        color: 'rgb(0,0,72)',
        fontSize: 10,
        border: 0,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 9,
        border: 0,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const columnMapping = {
    repoInitial: [
        { id: 'assetId', label: 'Asset Id' },
        { id: 'assetName', label: 'Asset Name' },
        { id: 'releaseName', label: 'Release Name' },
        { id: 'EvaluationDoneOn', label: 'Evaluation Done On' },
        { id: 'repositoryName', label: 'Repository Name' },
        { id: 'technology', label: 'Technology' },
        { id: 'initialReadMe', label: 'ReadMe' },
        { id: 'sonarUnitTestsPresent', label: 'Unit Tests Present' },
        { id: 'sonarUnitTestCoverage', label: 'Unit Test Coverage' },
        { id: 'sonarNumberOfLines', label: 'No. Of Lines' },
        { id: 'sonarReliabilityScore', label: 'Reliability Score' },
        { id: 'sonarMaintainabilityRating', label: 'Maintainability Rating' },
        { id: 'sonarDuplicationPercentage', label: 'Duplication Percentage' },
        { id: 'sonarCommentsPercentage', label: 'Comments Percentage' },
        { id: 'sastHigh', label: 'SAST (H)' },
        { id: 'sastMedium', label: 'SAST (M)' },
        { id: 'sastLow', label: 'SAST (L)' },
        { id: 'fossSecurityCritical', label: 'FOSS Security (C)' },
        { id: 'fossSecurityHigh', label: 'FOSS Security (H)' },
        { id: 'fossSecurityMedium', label: 'FOSS Security (M)' },
        { id: 'fossSecurityLow', label: 'FOSS Security (L)' },
        { id: 'fossLicenseHigh', label: 'FOSS License (H)' },
        { id: 'fossLicenseMedium', label: 'FOSS License (M)' },
        { id: 'fossLicenseLow', label: 'FOSS License (L)' },
        { id: 'sonarReliabilityBugBlocker', label: 'Reliability Bug (B)' },
        { id: 'sonarReliabilityBugCritical', label: 'Reliability Bug (C)' },
        { id: 'sonarReliabilityBugMajor', label: 'Reliability Bug (M)' },
        { id: 'sonarReliabilityBugMinor', label: 'Reliability Bug (m)' },
        { id: 'Comments', label: 'Comments' },
        {
            id: 'projectArchived',
            label: 'Project Archived',
        },
    ],
    repoFinal: [
        { id: 'assetId', label: 'Asset Id' },
        { id: 'assetName', label: 'Asset Name' },
        { id: 'releaseName', label: 'Release Name' },
        { id: 'EvaluationDoneOn', label: 'Evaluation Done On' },
        { id: 'repositoryName', label: 'Repository Name' },
        { id: 'technology', label: 'Technology' },
        { id: 'FinalReadMe', label: 'ReadMe' },
        { id: 'sonarUnitTestsPresent', label: 'Unit Tests Present' },
        { id: 'sonarUnitTestCoverage', label: 'Unit Test Coverage' },
        { id: 'sonarNumberOfLines', label: 'No. Of Lines' },
        { id: 'sonarReliabilityScore', label: 'Reliability Score' },
        { id: 'sonarMaintainabilityRating', label: 'Maintainability Rating' },
        { id: 'sonarDuplicationPercentage', label: 'Duplication Percentage' },
        { id: 'sonarCommentsPercentage', label: 'Comments Percentage' },
        { id: 'sastHigh', label: 'SAST (H)' },
        { id: 'sastMedium', label: 'SAST (M)' },
        { id: 'sastLow', label: 'SAST (L)' },
        { id: 'fossSecurityCritical', label: 'FOSS Security (C)' },
        { id: 'fossSecurityHigh', label: 'FOSS Security (H)' },
        { id: 'fossSecurityMedium', label: 'FOSS Security (M)' },
        { id: 'fossSecurityLow', label: 'FOSS Security (L)' },
        { id: 'fossLicenseHigh', label: 'FOSS License (H)' },
        { id: 'fossLicenseMedium', label: 'FOSS License (M)' },
        { id: 'fossLicenseLow', label: 'FOSS License (L)' },
        { id: 'sonarReliabilityBugBlocker', label: 'Reliability Bug (B)' },
        { id: 'sonarReliabilityBugCritical', label: 'Reliability Bug (C)' },
        { id: 'sonarReliabilityBugMajor', label: 'Reliability Bug (M)' },
        { id: 'sonarReliabilityBugMinor', label: 'Reliability Bug (m)' },
        { id: 'Comments', label: 'Comments' },
        {
            id: 'projectArchived',
            label: 'Project Archived',
        },
    ],
    assetInitial: [
        { id: 'assetId', label: 'Asset Id' },
        { id: 'assetName', label: 'Asset Name' },
        { id: 'classification', label: 'Classification' },
        { id: 'releaseName', label: 'Release Name' },
        { id: 'EvaluationDoneOn', label: 'Evaluation Done On' },
        { id: 'NumberOfRepos', label: 'Number Of Repos' },
        // { id: 'scanType', label: 'Scan Type' },
        { id: 'applicationSecurityTesting', label: 'Application Security Testing' },
        { id: 'dependencyCompliance', label: 'Dependency Compliance' },
        { id: 'reliabilityBugs', label: 'Reliability Bugs' },
        { id: 'maintainability', label: 'Maintainability' },
        { id: 'documenatation', label: 'Documenatation' },
        { id: 'unitTestCoverage', label: 'Unit Test Coverage' },
        { id: 'duplicationPercentage', label: 'Duplication Percentage' },
        { id: 'commentDensity', label: 'Comment Density' },
        { id: 'description', label: 'Description' },
        { id: 'architectureDiagram', label: 'Architecture Diagram' },
        { id: 'uiDesign', label: 'UI Design' },
        { id: 'releaseNotes', label: 'Release Notes' },
        { id: 'userGuide', label: 'User Guide' },
        { id: 'projectArchived', label: 'Project Archived' },
    ],
    assetFinal: [
        { id: 'assetId', label: 'Asset Id' },
        { id: 'assetName', label: 'Asset Name' },
        { id: 'projectArchived', label: 'Project Archived' },
        { id: 'classification', label: 'Classification' },
        { id: 'releaseName', label: 'Release Name' },
        { id: 'EvaluationDoneOn', label: 'Evaluation Done On' },
        { id: 'NumberOfRepos', label: 'Number Of Repos' },
        { id: 'applicationSecurityTesting', label: 'Application Security Testing' },
        { id: 'dependencyCompliance', label: 'Dependency Compliance' },
        { id: 'reliabilityBugs', label: 'Reliability Bugs' },
        { id: 'maintainability', label: 'Maintainability' },
        { id: 'documenatation', label: 'Documenatation' },
        { id: 'unitTestCoverage', label: 'Unit Test Coverage' },
        { id: 'duplicationPercentage', label: 'Duplication Percentage' },
        { id: 'commentDensity', label: 'Comment Density' },
        { id: 'description', label: 'Description' },
        { id: 'architectureDiagram', label: 'Architecture Diagram' },
        { id: 'uiDesign', label: 'UI Design' },
        { id: 'releaseNotes', label: 'Release Notes' },
        { id: 'userGuide', label: 'User Guide' },
        { id: 'sastHighEx', label: 'sast (H) - Exception' },
        { id: 'sastMediumEx', label: 'sast (M) - Exception' },
        { id: 'sastHighFP', label: 'sast (H) - FP' },
        { id: 'sastMediumFP', label: 'sast (M) - FP' },
        { id: 'fossSecurityCriticalEx', label: 'fossSecurity (C) - Exception' },
        { id: 'fossSecurityHighEx', label: 'fossSecurity (H) - Exception' },
        { id: 'fossSecurityMediumEx', label: 'fossSecurity (M) - Exception' },
        { id: 'fossSecurityCriticalFP', label: 'fossSecurity (C) - FP' },
        { id: 'fossSecurityHighFP', label: 'fossSecurity (H) - FP' },
        { id: 'fossSecurityMediumFP', label: 'fossSecurity (M) - FP' },
        { id: 'sonarReliabilityBugBlockerEx', label: 'sonarReliabilityBugBlocker - Exception' },
        { id: 'sonarReliabiltyBugCriticalEx', label: 'sonarReliabiltyBugCritical - Exception' },
        { id: 'sonarReliabilityBugBlockerFP', label: 'sonarReliabilityBugBlocker - FP' },
        { id: 'sonarReliabiltyBugCriticalFP', label: 'sonarReliabiltyBugCritical - FP' },
        { id: 'projectArchived', label: 'Project Archived' },
    ],
};

function getValue(row: any, columnId: string): any {
    return columnId
        .split('.')
        .reduce((obj: any, key: string) => (obj && obj[key] !== 'undefined' ? obj[key] : null), row);
}

const renderTableCell = (row: any, columnId: string) => {
    const value = getValue(row, columnId);
    if (value === true) {
        return 'Yes';
    } else if (value === false) {
        return 'No';
    } else if (value === null) {
        return 'NA';
    } else {
        return value;
    }
};

const EvaluationTracker: React.FC = () => {
    const [data, setData] = useState<any[] | null>(null);
    const [repoInitialData, setRepoInitialData] = useState<any[] | null>(null);
    const [repoFinalData, setRepoFinalData] = useState<any[] | null>(null);
    const [assetInitialData, setAssetInitialData] = useState<any[] | null>(null);
    const [assetFinalData, setAssetFinalData] = useState<any[] | null>(null);
    const [dataSource, setDataSource] = useState('assetInitial');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const columns = columnMapping[dataSource];
    const downloadExcel = () => {
        const wb = XLSX.utils.book_new();
        const ws1 = XLSX.utils.json_to_sheet(assetInitialData || []);
        const ws2 = XLSX.utils.json_to_sheet(repoInitialData || []);
        const ws3 = XLSX.utils.json_to_sheet(assetFinalData || []);
        const ws4 = XLSX.utils.json_to_sheet(repoFinalData || []);

        const s2ab = (s: string) => {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) {
                // tslint:disable-next-line:no-bitwise
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buf;
        };
        const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
        const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;

        XLSX.utils.book_append_sheet(wb, ws1, 'Asset Initial');
        XLSX.utils.book_append_sheet(wb, ws2, 'Repo Initial');
        XLSX.utils.book_append_sheet(wb, ws3, 'Asset Final');
        XLSX.utils.book_append_sheet(wb, ws4, 'Repo Final');

        const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
        const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
        const url = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = url;
        link.download = `EvaluationTracker-${formattedStartDate}_to_${formattedEndDate}.xlsx`;
        link.click();
    };

    const fetchData = async (startDate: Date | null, endDate: Date | null) => {
        setIsSubmitted(true);
        const headers = getRequestHeader();
        const formattedStartDate = startDate ? format(startDate, 'yyyy-MM-dd') : null;
        const formattedEndDate = endDate ? format(endDate, 'yyyy-MM-dd') : null;
        const apiUrl = `${process.env.REACT_APP_URL}/api/v1/codehubprojects/scorecardDetails?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
        try {
            const result = await axios(apiUrl, { headers });

            if (result.data) {
                if (Array.isArray(result.data.assetInitial)) {
                    setAssetInitialData(result.data.assetInitial.flat());
                }
                if (Array.isArray(result.data.repoInitial)) {
                    setRepoInitialData(result.data.repoInitial.flat());
                }
                if (Array.isArray(result.data.assetFinal)) {
                    setAssetFinalData(result.data.assetFinal.flat());
                }
                if (Array.isArray(result.data.repoFinal)) {
                    setRepoFinalData(result.data.repoFinal.flat());
                }
                setData(result.data.assetInitial.flat());
                setIsSubmitted(false);
            } else {
                // console.error('API result.data is not in the expected format:', result.data);
            }
        } catch (error) {
            // console.error('API request failed', error);
        }
    };

    const handleChangeDataSource = (event: SelectChangeEvent<string>) => {
        setDataSource(event.target.value as string);
        switch (event.target.value) {
            case 'assetInitial':
                setData(assetInitialData);
                break;
            case 'repoInitial':
                setData(repoInitialData);
                break;
            case 'assetFinal':
                setData(assetFinalData);
                break;
            case 'repoFinal':
                setData(repoFinalData);
                break;
            default:
                setData(null);
        }
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    return (
        <div className="container">
            <h3>Evaluation Tracker</h3>
            <p>Select Dates</p>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <div>
                    <span className="testClass">
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={(newDate) => setStartDate(newDate)}
                            renderInput={(params) => <TextField {...params} />}
                            maxDate={new Date()}
                        />
                    </span>
                    <span className="testClass">
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            onChange={(newDate) => setEndDate(newDate)}
                            renderInput={(params) => <TextField {...params} />}
                            maxDate={new Date()}
                        />
                    </span>

                    <Button
                        onClick={() => {
                            fetchData(startDate, endDate);
                            setIsSubmitted(true);
                        }}
                        disabled={!startDate || !endDate || isSubmitted}
                    >
                        Submit
                    </Button>
                </div>
            </LocalizationProvider>

            {isSubmitted ? (
                <Box className="circularProgress">
                    <h5>Please wait while we are fetching the Content...</h5>

                    <CircularProgress />
                </Box>
            ) : data ? (
                <>
                    <Select
                        className="dropdown"
                        value={dataSource}
                        onChange={(event: SelectChangeEvent<string>) => handleChangeDataSource(event)}
                    >
                        <MenuItem value={'assetInitial'}>Asset Initial</MenuItem>
                        <MenuItem value={'repoInitial'}>Repo Initial</MenuItem>
                        <MenuItem value={'assetFinal'}>Asset Final</MenuItem>
                        <MenuItem value={'repoFinal'}>Repo Final</MenuItem>
                    </Select>
                    <TableContainer className="tableContainer">
                        <Table stickyHeader aria-label="sticky table" className="table">
                            <TableHead>
                                <TableRow>
                                    {columns.map((column: any) => (
                                        <StyledTableCell key={column.id}>{column.label}</StyledTableCell>
                                    ))}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data &&
                                    data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                                        <StyledTableRow key={row.id}>
                                            {columns.map((column: any) => (
                                                <StyledTableCell key={column.id}>
                                                    {renderTableCell(row, column.id)}
                                                </StyledTableCell>
                                            ))}
                                        </StyledTableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 100, 200]}
                        component="div"
                        count={data ? data.length : 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                    <Box display="flex" justifyContent="flex-end">
                        <Button className="downloadButton" onClick={downloadExcel}>
                            Download Excel
                        </Button>
                    </Box>
                </>
            ) : null}
        </div>
    );
};

export default EvaluationTracker;
