import { useEffect } from 'react';
import { Paper, Card, CardContent, Typography, Skeleton } from '@mui/material';
import { fetchData } from '../../../utils/fetchMetrix';

const API_URL = process.env.REACT_APP_URL;
export default function GithubCard(props: any) {
    const fetchGithubData = async (doHardRefresh: boolean) => {
        const gitHubURL = API_URL + `/api/v1/metric/github?refresh=${doHardRefresh}`;

        await fetchData(gitHubURL, (data: any) => {
            if (data) {
                //  setGithubQuickStartCount(data.quickstartTotalRepoCount);
            }
        });
    };

    useEffect(() => {
        fetchGithubData(false);
    }, []);

    return (
        <>
            <Paper elevation={3}>
                <Card variant="outlined" sx={{ background: props.color, color: '#000048', textAlign: 'center' }}>
                    <CardContent>
                        <Typography color="text.secondary">{props.type}</Typography>
                        <Typography variant="h2" component="div">
                            {props.value === null ? (
                                <Skeleton
                                    variant="rectangular"
                                    width={120}
                                    height={70}
                                    sx={{
                                        margin: ' 30px auto 30px auto',
                                        display: 'block',
                                    }}
                                />
                            ) : props.type === 'Child Teams Without any Repos' ? (
                                <p color="#ff0000">{props.value}</p>
                            ) : props.type === 'Child Teams Without any Members' ? (
                                <div style={{ color: 'orange' }}>{props.value}</div>
                            ) : (
                                props.value
                            )}
                        </Typography>
                        <Typography sx={{ fontSize: 15 }} color="#000048" gutterBottom>
                            GitHub Teams
                        </Typography>
                    </CardContent>
                </Card>
            </Paper>
        </>
    );
}
