import { createContext } from 'react';

export interface Response {
    loading: boolean;
    title: string;
    columnAndData: any;
}
interface ServerResponse extends Response {
    responseUpdater: (property: string, value: any) => void;
}

export const userDetailContext = createContext<any | null>(null);
export const responseContext = createContext<ServerResponse | null>(null);
