import { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Typography,
    CircularProgress,
    Backdrop,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import xlsx from 'xlsx';
import { saveAs } from 'file-saver';
import { xlsxToJson } from './../../../utils/readExcelSheet';
import { API_URL } from '../../../utils/constants';
import { getRequestHeader } from './../../../utils/Keycloak';
import { useUserDetail } from './../../../hooks/index';
import FileUploadButton from './FileUploadButton';
import TableDialog from './../../TableDialog';

const btnFill = { background: '#000062', borderRadius: 0, boxShadow: 'none', color: '#ffffff' };
const btnOutline = { borderColor: '#000062', borderRadius: 0, boxShadow: 'none', color: '#000062' };
const btnDisabled = { ...btnFill, background: '#adb5bd' };

interface ListofUsers {
    repoUsers: [];
}

interface Permissions {
    admin: boolean;
    maintain: boolean;
    push: boolean;
    triage: boolean;
    pull: boolean;
}

interface RepoUsers {
    name: string;
    login: string;
    permissions: Permissions;
    roleName: string;
}

export default function ListOfRepoUsers(props: any) {
    const [excelUpload, setExcelUpload] = useState(false);
    const [data, setData] = useState<FormData>();
    const [jsonData, setJsonData] = useState<ListofUsers>({ repoUsers: [] });
    const [confirmCreate, setConfirmCreate] = useState(false);
    const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
    const [resData, setResData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    //   const pageResponse = useResponse();
    const user = useUserDetail();

    const handleClose = () => {
        props.onClose(false);
        setOpenDownloadDialog(false);
        resetstate();
    };
    const resetstate = () => {
        setExcelUpload(false);
        setConfirmCreate(false);
    };

    const handleSubmit = () => {
        handleClose();
        setIsLoading(true);
        const header = getRequestHeader();
        fetch(API_URL + '/api/v1/upload', {
            body: data,
            method: 'POST',
            headers: header,
        })
            .then((response) => {
                return response.json();
            })
            .then((responseData) => {
                const resultData = JSON.parse(JSON.stringify(responseData));
                setResData(resultData.response.repoUsers);
                resetstate();
                setIsLoading(false);
                setOpenDownloadDialog(true);
            })
            .catch(() => {
                resetstate();
            });
    };
    const handleConfirmDialog = (val: boolean) => {
        setConfirmCreate(val);
        setExcelUpload(false);
    };

    const mapColumnData = (map: [], sheet: string) => {
        setJsonData((prev: ListofUsers) => ({
            ...prev,
            [sheet]: map,
        }));
    };

    const upload = (event: any) => {
        if (event.target.files.item(0) != null) {
            setExcelUpload(true);
            const data = new FormData();
            data.append('intake', event.target.files.item(0));
            data.append('createdBy', user.email);
            setData(data);

            xlsxToJson(data.get('intake'), 'ReposTogetUsersList', (repos: any) => {
                mapColumnData(repos, 'Repos To Get Users');
            });
        }
    };

    const handleDownload = () => {
        try {
            if (resData) {
                const repoUsersWorksheetData = resData.map((user: RepoUsers) => [
                    user.name,
                    user.login,
                    JSON.stringify(user.permissions),
                    user.roleName,
                ]);
                const workbook = xlsx.utils.book_new();
                const repoUsersWorksheet = xlsx.utils.aoa_to_sheet([
                    ['Repo Name', 'User Name', 'Permissions', 'Role Name'],
                    ...repoUsersWorksheetData,
                ]);
                xlsx.utils.book_append_sheet(workbook, repoUsersWorksheet, 'Repo Users');
                const excelBuffer = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
                });
                saveAs(data, 'repoSpecificUsers.xlsx');
            } else {
                // tslint:disable-next-line:no-console
                console.log('resData is empty or not an array');
            }
        } catch (error) {
            // tslint:disable-next-line:no-console
            console.error(error);
        }
    };

    return (
        <>
            <Dialog open={props.listOfRepoUsers} aria-labelledby="responsive-dialog-title">
                <Box style={{ textAlign: 'right' }}>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <DialogTitle id="responsive-dialog-title">
                    <DialogContentText>Upload a File</DialogContentText>
                    <Typography style={{ fontSize: '1.7em' }}> {'Repos to get their users'} </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <FileUploadButton style={btnFill} accept={['.xlsx']} onChange={(e: Event) => upload(e)} />
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ background: '#F1F1F1', padding: '2em' }}>
                    <Button autoFocus onClick={handleClose} variant="outlined" style={btnOutline}>
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        style={confirmCreate ? btnFill : btnDisabled}
                        disabled={!confirmCreate}
                    >
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <Backdrop open={isLoading} style={{ color: '#fff', zIndex: 1500, flexDirection: 'column' }}>
                <CircularProgress color="inherit" />
                <Typography>Loading...</Typography>
            </Backdrop>
            <Dialog
                open={openDownloadDialog}
                onClose={() => setOpenDownloadDialog(false)}
                aria-labelledby="responsive-dialog-title"
            >
                <Box style={{ textAlign: 'right' }}>
                    <IconButton onClick={handleClose}>
                        <Close />
                    </IconButton>
                </Box>
                <DialogTitle id="responsive-dialog-title" style={{ fontSize: '1.7em' }}>
                    {'List of users having access to the provided repos'}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ fontSize: '1.7em' }}>
                        {'Your file is ready to download.'}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{ background: '#F1F1F1', padding: '2em' }}>
                    <Button
                        autoFocus
                        onClick={() => setOpenDownloadDialog(false)}
                        variant="outlined"
                        style={btnOutline}
                    >
                        cancel
                    </Button>
                    <Button
                        autoFocus
                        variant="contained"
                        style={btnFill}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleDownload()}
                    >
                        Download
                    </Button>
                </DialogActions>
            </Dialog>
            <>
                <TableDialog
                    open={excelUpload}
                    onClose={resetstate}
                    onConfirm={handleConfirmDialog}
                    title={'Do you want to get the users list for the repos ?'}
                    columnAndData={jsonData}
                />
            </>
        </>
    );
}
