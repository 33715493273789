import Keycloak from 'keycloak-js';
import axios from 'axios';

let keycloak: any = null;

export const getTenantConfig = async () => {
    const response = await axios.get(`${process.env.REACT_APP_URL}/api/v1/auth/keycloakConfig`);
    return response.data;
};

export const getKeycloak = async () => {
    const kcConfig = await getTenantConfig();
    const tt = {
        realm: kcConfig.realm,
        url: kcConfig.keycloakUrl,
        clientId: kcConfig.clientId,
        'public-client': true,
    };
    if (!keycloak) {
        keycloak = Keycloak(tt);
    }
    return keycloak;
};

export function setKeyCloakAuthToken(token: string) {
    sessionStorage.setItem('jwt', token);
}

export function getKeyCloakAuthToken() {
    return sessionStorage.getItem('jwt');
}

export const getRequestHeader = () => {
    return {
        Authorization: `Bearer ${sessionStorage.getItem('jwt')}`,
    };
};

export const getRequestHeaderParameters = () => {
    const token = getKeycloak().then((res) => res.token);
    const headerParams = {
        'Content-Type': 'application/json',
        authorization: `Bearer ${token}`,
    };
    return headerParams;
};
