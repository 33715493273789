import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TableContainer,
    Table,
    TableRow,
    TableHead,
    Paper,
    TableCell,
    TableBody,
    Grid,
} from '@mui/material';
import { useState } from 'react';
import '../StyledComponents/GithubRepoTable.css';

interface RepoCountProps {
    repoCounts: {
        environment: string;
        count: number | undefined | null;
        archived: number | undefined | null;
        color: any;
    }[];
    tableHeading: string;
}

const GithubRepoTable: React.FC<RepoCountProps> = ({ repoCounts, tableHeading }) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleButtonClick = () => {
        setIsOpen(true);
    };

    const getStatusColorClass = (color: string) => {
        switch (color) {
            case 'amber':
                return 'amber';
            case 'green':
                return 'green';
            case 'red':
                return 'red';
            case 'black':
                return 'black';
            default:
                return '';
        }
    };

    return (
        <>
            <div>
                <Grid container justifyContent="center" alignItems="center">
                    <Button variant="outlined" id="button" title="View More" onClick={handleButtonClick}>
                        View More
                    </Button>
                </Grid>
                <Dialog open={isOpen} onClose={handleClose}>
                    <DialogTitle sx={{ textAlign: 'center' }}>{tableHeading}</DialogTitle>
                    <DialogContent>
                        <TableContainer component={Paper} sx={{ border: '3px solid #060667' }}>
                            <Table sx={{ minWidth: 500 }} aria-label="a dense table">
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: '#060667' }}>
                                        <TableCell sx={{ color: '#ffffff' }}>Classification</TableCell>
                                        <TableCell sx={{ color: '#ffffff', minWidth: 90 }}>Total Count</TableCell>
                                        <TableCell sx={{ color: '#ffffff' }}>Archived</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {repoCounts.map(({ environment, count, archived, color }) => (
                                        <TableRow key={environment}>
                                            <TableCell>
                                                {environment}
                                                {environment === 'Contains Code' ? (
                                                    <Tooltip
                                                        title="Repositories to be followed up with respective owners to on-board to CodeHub"
                                                        sx={{ height: '5%', width: '5%' }}
                                                    >
                                                        <InfoOutlinedIcon
                                                            sx={{ paddingLeft: '10px', fontSize: '15px' }}
                                                        />
                                                    </Tooltip>
                                                ) : environment === 'Empty Repos' || environment === 'Codehub Stage' ? (
                                                    <Tooltip
                                                        title="Repositories which need to be verify & delete"
                                                        sx={{ height: '5%', width: '5%' }}
                                                    >
                                                        <InfoOutlinedIcon
                                                            sx={{ paddingLeft: '10px', fontSize: '15px' }}
                                                        />
                                                    </Tooltip>
                                                ) : environment === 'CodeHub Local' ||
                                                  environment === 'Codehub Development' ||
                                                  environment === 'CodeHub QA' ||
                                                  environment === 'Quickstart Local' ||
                                                  environment === 'Quickstart Dev' ||
                                                  environment === 'Quickstart QA' ||
                                                  environment === 'Quickstart Stage' ? (
                                                    <Tooltip
                                                        title="Repositories to be deleted"
                                                        sx={{ height: '5%', width: '5%' }}
                                                    >
                                                        <InfoOutlinedIcon
                                                            sx={{ paddingLeft: '10px', fontSize: '15px' }}
                                                        />
                                                    </Tooltip>
                                                ) : environment === 'Quickstart Architecture' ||
                                                  environment === 'Quickstart BluePrint' ||
                                                  environment === 'Quickstart Prototype' ? (
                                                    <Tooltip
                                                        title="Quickstart dev repos"
                                                        sx={{ height: '5%', width: '5%' }}
                                                    >
                                                        <InfoOutlinedIcon
                                                            sx={{ paddingLeft: '10px', fontSize: '15px' }}
                                                        />
                                                    </Tooltip>
                                                ) : environment === 'Quickstart Prod' ? (
                                                    <Tooltip
                                                        title="Quickstart production assets"
                                                        sx={{ height: '5%', width: '5%' }}
                                                    >
                                                        <InfoOutlinedIcon
                                                            sx={{ paddingLeft: '10px', fontSize: '15px' }}
                                                        />
                                                    </Tooltip>
                                                ) : environment === 'Linked but Empty' ? (
                                                    <Tooltip
                                                        title="To be followed up on code upload"
                                                        sx={{ height: '5%', width: '5%' }}
                                                    >
                                                        <InfoOutlinedIcon
                                                            sx={{ paddingLeft: '10px', fontSize: '15px' }}
                                                        />
                                                    </Tooltip>
                                                ) : environment === 'Linked in CodeHub' ? (
                                                    <Tooltip title="IPReady assets" sx={{ height: '5%', width: '5%' }}>
                                                        <InfoOutlinedIcon
                                                            sx={{ paddingLeft: '10px', fontSize: '15px' }}
                                                        />
                                                    </Tooltip>
                                                ) : (
                                                    ''
                                                )}
                                            </TableCell>
                                            <TableCell
                                                id={getStatusColorClass(color)}
                                                sx={{ minWidth: 50, textAlign: 'center' }}
                                            >
                                                {count}
                                            </TableCell>
                                            <TableCell>{archived}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Close</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    );
};

export default GithubRepoTable;
