import * as React from 'react';
import {
    Button,
    Menu,
    MenuItem,
    Fade,
    Box,
    IconButton,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Divider,
} from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import { ExpandMore, GitHub } from '@mui/icons-material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import DeleteGitHubRepo from './DeleteGitHubRepo';
import SendGitHubUserInvitation from './SendGitHubUserInvitation';
import CreateGitHubRepoTeam from './CreateGitHubRepoTeam';
import DeleteGitHubTeam from './DeleteGitHubTeam';
import ListOfRepoUsers from './ListOfRepoUsers';
// import CircleCiEnable from './EnableCircleCi';
// import CiImage from '../../../assets/images/circleci.svg';

interface Feature {
    createGitHubRepoTeam: boolean;
    sendGitHubUserInvitation: boolean;
    deleteGitHubRepo: boolean;
    deleteGitHubTeam: boolean;
    circleCiEnable: boolean;
    listOfRepoUsers: boolean;
}
export default function FadeMenu(props: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [popOver, setPopOver] = React.useState<Feature>({
        createGitHubRepoTeam: false,
        sendGitHubUserInvitation: false,
        circleCiEnable: false,
        deleteGitHubRepo: false,
        deleteGitHubTeam: false,
        listOfRepoUsers: false,
    });

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeState = (value: boolean, featureName: string) => {
        setPopOver((prevState: Feature) => ({ ...prevState, [featureName]: value }));
    };

    return (
        <>
            <div>
                <Button
                    id="fade-button"
                    aria-controls={open ? 'fade-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    onClick={handleClick}
                    sx={{ color: props.color, display: { xs: 'none', md: 'flex' } }}
                >
                    <Box style={{ color: props.color, padding: '8px' }}>
                        <GitHub />
                    </Box>
                    GitHub
                    <ArrowDropDownIcon />
                </Button>
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{ display: { xs: 'none', md: 'block' } }}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            changeState(true, 'createGitHubRepoTeam');
                        }}
                    >
                        <IconButton>
                            <AddCircleRoundedIcon style={{ color: '#000062' }} />
                        </IconButton>
                        Create GitHub Repo & Team
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            changeState(true, 'sendGitHubUserInvitation');
                        }}
                    >
                        <IconButton>
                            <SendRoundedIcon style={{ color: '#000062' }} />
                        </IconButton>
                        Send User Invitation
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            changeState(true, 'listOfRepoUsers');
                        }}
                    >
                        <IconButton>
                            <AccountCircle style={{ color: '#000062' }} />
                        </IconButton>
                        Get Repo Users
                    </MenuItem>
                    {/* <MenuItem
                        onClick={() => {
                            handleClose();
                            changeState(true, 'circleCiEnable');
                        }}
                    >
                        <IconButton> */}
                    {/* <img src={CiImage} alt="CircleCi Icon" />
                        </IconButton>
                        Enable Circle CI
                    </MenuItem> */}
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            changeState(true, 'deleteGitHubRepo');
                        }}
                    >
                        <IconButton>
                            <DeleteRoundedIcon style={{ color: '#000062' }} />
                        </IconButton>
                        Delete GitHub Repo
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            changeState(true, 'deleteGitHubTeam');
                        }}
                    >
                        <IconButton>
                            <DeleteRoundedIcon style={{ color: '#000062' }} />
                        </IconButton>
                        Delete GitHub Team
                    </MenuItem>
                </Menu>
                <Accordion sx={{ padding: 0, display: { xs: 'block', md: 'none' } }}>
                    <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                        <Box sx={{ margin: 0 }}>
                            <IconButton style={{ color: props.color }}>
                                <GitHub />
                            </IconButton>
                            GitHub
                        </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                        <MenuItem
                            onClick={() => {
                                props.close();
                                changeState(true, 'createGitHubRepoTeam');
                            }}
                        >
                            <IconButton>
                                <AddCircleRoundedIcon style={{ color: '#000062' }} />
                            </IconButton>
                            Create GitHub Repo & Team
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                props.close();
                                changeState(true, 'sendGitHubUserInvitation');
                            }}
                        >
                            <IconButton>
                                <SendRoundedIcon style={{ color: '#000062' }} />
                            </IconButton>
                            Send User Invitation
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                props.close();
                                changeState(true, 'listOfRepoUsers');
                            }}
                        >
                            <IconButton>
                                <SendRoundedIcon style={{ color: '#000062' }} />
                            </IconButton>
                            Get Repo Users
                        </MenuItem>
                        <Divider />
                        {/* This is disabled As we started using Github actions
                        <MenuItem
                            onClick={() => {
                                handleClose();
                                changeState(true, 'circleCiEnable');
                            }}
                        >
                            <IconButton>
                        <img src={CiImage} alt="CircleCi Icon" />
                            </IconButton>
                            Enable Circle CI
                        </MenuItem>
                {/* <Divider /> */}
                        <MenuItem
                            onClick={() => {
                                props.close();
                                changeState(true, 'deleteGitHubRepo');
                            }}
                        >
                            <IconButton>
                                <DeleteRoundedIcon style={{ color: '#000062' }} />
                            </IconButton>
                            Delete GitHub Repo
                        </MenuItem>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                props.close();
                                changeState(true, 'deleteGitHubTeam');
                            }}
                        >
                            <IconButton>
                                <DeleteRoundedIcon style={{ color: '#000062' }} />
                            </IconButton>
                            Delete GitHub Team
                        </MenuItem>
                    </AccordionDetails>
                </Accordion>
            </div>
            <Box>
                <CreateGitHubRepoTeam
                    createGitHubRepoTeam={popOver.createGitHubRepoTeam}
                    onClose={(value: boolean) => changeState(value, 'createGitHubRepoTeam')}
                />
                <SendGitHubUserInvitation
                    sendGitHubUserInvitation={popOver.sendGitHubUserInvitation}
                    onClose={(value: boolean) => changeState(value, 'sendGitHubUserInvitation')}
                />
                {/* <CircleCiEnable
                circleCiEnable={popOver.circleCiEnable}
                onClose={(value: boolean) => changeState(value, 'circleCiEnable')}
            /> */}
                <DeleteGitHubRepo
                    deleteGitHubRepo={popOver.deleteGitHubRepo}
                    onClose={(value: boolean) => changeState(value, 'deleteGitHubRepo')}
                />
                <DeleteGitHubTeam
                    deleteGitHubTeam={popOver.deleteGitHubTeam}
                    onClose={(value: boolean) => changeState(value, 'deleteGitHubTeam')}
                />
                <ListOfRepoUsers
                    listOfRepoUsers={popOver.listOfRepoUsers}
                    onClose={(value: boolean) => changeState(value, 'listOfRepoUsers')}
                />
            </Box>
        </>
    );
}
