import React, { useEffect, useState } from 'react';
import axios from 'axios';
import TextField from '@mui/material/TextField';
import { Button, Card, CardActions, CardContent } from '@mui/material';
import jsPDF from 'jspdf';
import Autocomplete from '@mui/material/Autocomplete';
import { getRequestHeader } from '../../../utils/Keycloak';
import '../StyledComponents/SonarReport.css';
import Sonarqube from '../../../assets/images/Sonarqube.png';
import Severity from '../../../assets/images/Severity.png';

interface KeysData {
    projectKeys: string[];
}

const API_URL = process.env.REACT_APP_URL;
const SonarReport: React.FC = () => {
    const [sonarData, setSonarData] = useState<any[] | null>(null);
    const [componentNamesArray, setComponentNamesArray] = useState<string[]>([]);
    const [fetchDataStatus, setFetchDataStatus] = useState<boolean>(false);

    const [projectKeys, setProjectKeys] = useState<string[]>([]);

    const [selectedProjectKeys, setSelectedProjectKeys] = useState<string[]>([]);

    const [filteredProjectKeys, setFilteredProjectKeys] = useState<string[]>([]);

    const [searchTerm, setSearchTerm] = useState<string>('');

    const fetchDataForComponent = async (componentName: string) => {
        const headers = getRequestHeader();
        const apiUrl = `${process.env.REACT_APP_URL}/api/v1/sonarreport/sonarmetrics?componentName=${componentName}`;
        const response = await axios.get(apiUrl, { headers });
        if (response.status === 200) {
            const data = response.data;
            return data;
        }
    };
    useEffect(() => {
        const headers = getRequestHeader();
        const apiUrl = `${API_URL}/api/v1/sonarkeys/sonarkeysmetrics`;
        axios.get<KeysData>(apiUrl, { headers }).then((response) => {
            if (response.status === 200) {
                setProjectKeys(response.data.projectKeys);
            }
        });
    }, []);

    useEffect(() => {
        const filteredKeys = projectKeys.filter((key) => key.toLowerCase().includes(searchTerm.toLowerCase()));
        setFilteredProjectKeys(filteredKeys);
    }, [searchTerm, projectKeys]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        if (fetchDataStatus) {
            const fetchData = async () => {
                const dataForComponents = await Promise.all(
                    selectedProjectKeys.map((projectKeys) => fetchDataForComponent(projectKeys))
                );
                const filteredData = dataForComponents.filter((data) => data !== null);
                setSonarData(filteredData);
                setComponentNamesArray(selectedProjectKeys);
                setFetchDataStatus(false);
            };
            fetchData();
        }
    }, [fetchDataStatus, selectedProjectKeys]);
    const fetchingData = () => {
        setFetchDataStatus(true);
    };
    const clearData = () => {
        setSonarData(null);
        setComponentNamesArray([]);
        setSelectedProjectKeys([]);
    };

    const handleDownloadPDF = () => {
        if (sonarData !== null && sonarData.length > 0) {
            const doc = new jsPDF();
            doc.setFillColor(243, 243, 243);
            doc.rect(0, 0, 210, 297, 'F');
            const totalPages = sonarData.length;
            sonarData.forEach((data, index) => {
                if (index > 0) {
                    doc.addPage();
                    doc.setFillColor(243, 243, 243);
                    doc.rect(0, 0, 210, 297, 'F');
                }

                const currentPage = index + 1;
                const pageNumber = `${currentPage}/${totalPages}`;
                const rectX = 5;
                const rectY = 5;
                const rectWidth = 200;
                const rectHeight = 63;
                doc.rect(rectX, rectY, rectWidth, rectHeight, 'F');
                doc.setFontSize(15);
                doc.setTextColor(0, 0, 0);
                doc.setFillColor(255, 255, 255);
                doc.rect(rectX, rectY, rectWidth, rectHeight, 'F');

                const logoWidth = 40;
                const logoHeight = 10;
                doc.addImage(Sonarqube, 'PNG', 15, 13, logoWidth, logoHeight);
                doc.setFontSize(12);
                doc.text('IP Ready - Code Quality Report', 132, 21);

                doc.setDrawColor(10, 160, 0);
                doc.setLineWidth(0.3);
                doc.line(15, 24, 193, 24);

                let yPosition = 32;
                doc.setFontSize(9);
                doc.setTextColor(100, 100, 100);
                doc.text('Project:', 15, yPosition);
                doc.text('Project Name:', 15, yPosition + 7);
                doc.text('Lines of Code', 15, 47);
                doc.text('Technology', 60, 47.2);
                doc.text('Scanned Date', 170, yPosition);
                doc.text('Quality Gate', 170, 47);
                doc.setFontSize(10);
                doc.setTextColor(0, 0, 0);
                doc.text(pageNumber, 27, yPosition);

                yPosition += 7;
                doc.setFontSize(11);
                doc.setTextColor(51, 102, 187);
                const projectName = `${componentNamesArray[index]}`;
                const sonarLink = `https://codeinspect.codehub.cognizantproducts.com/dashboard?id=${projectName}`;
                doc.textWithLink(projectName, 37, yPosition, { url: sonarLink });

                yPosition += 16;
                doc.setFontSize(17);
                doc.setTextColor(0, 0, 0);
                const linesOfCodeText = `${data.lines}`;
                doc.text(linesOfCodeText, 15, yPosition);

                doc.setFontSize(11);
                const originalDate = new Date(data.date);
                const day = originalDate.getDate().toString().padStart(2, '0');
                const month = (originalDate.getMonth() + 1).toString().padStart(2, '0');
                const year = originalDate.getFullYear();
                const formattedDate = `${day}/${month}/${year}`;
                doc.text(formattedDate, 170, yPosition - 17);

                if (data.qualityGateStatus === 'Passed') {
                    doc.setFillColor(0, 175, 0);
                } else {
                    doc.setFillColor(255, 0, 0);
                }
                doc.roundedRect(168, yPosition - 5, 22, 6, 3, 3, 'F');
                doc.setFontSize(11);
                doc.setTextColor(255, 255, 255);
                const textWidthStatus = doc.getStringUnitWidth(data.qualityGateStatus) * doc.internal.scaleFactor;
                doc.text(data.qualityGateStatus, 166 + (22 - textWidthStatus) / 2, yPosition - 0.8);
                const technologies = data.language
                    .match(/[a-zA-Z]+(?==)/g)
                    ?.map((name: string) =>
                        name
                            .replace(/\bcs\b/g, 'C#')
                            .replace(/\bjava\b/g, 'Java')
                            .replace(/\bxml\b/g, 'XML')
                            .replace(/\bpy\b/g, 'Python')
                            .replace(/\bts\b/g, 'TypeScript')
                            .replace(/\bjs\b/g, 'JavaScript')
                            .replace(/\bcss\b/g, 'CSS')
                            .replace(/\bhtml\b/g, 'HTML')
                            .replace(/\byaml\b/g, 'Yaml')
                            .replace(/\bweb\b/g, 'Web')
                            .replace(/\bphp\b/g, 'PHP')
                    )
                    .join(', ');

                doc.setFontSize(11);
                doc.setTextColor(0, 0, 0);
                doc.text(technologies, 60, yPosition - 0.5);

                yPosition += 25;

                // Card for Reliability
                const cardX = 5;
                const cardY = 72;
                const cardWidth = 37;
                const cardHeight = 55;
                doc.setFontSize(8);
                doc.setTextColor(0, 0, 0);
                doc.setFillColor(255, 255, 255);
                doc.rect(cardX, cardY, cardWidth, cardHeight, 'F');
                doc.setTextColor(100, 100, 100);

                const centerX = cardX + cardWidth / 2;
                doc.text('Reliability', centerX - 6, cardY + 7);
                doc.text('Bugs', centerX - 4, cardY + 35);
                doc.setFontSize(20);
                doc.setTextColor(0, 0, 0);
                const totalBugs = `${data.bugs}`;
                const textWidth = doc.getStringUnitWidth(totalBugs) * doc.internal.scaleFactor;
                const textX = centerX - textWidth / 2;

                if (totalBugs.length === 1) {
                    doc.text(totalBugs, textX - 2, cardY + 45);
                } else if (totalBugs.length === 2) {
                    doc.text(totalBugs, textX - 3, cardY + 45);
                } else {
                    doc.text(totalBugs, textX - 5, cardY + 45);
                }

                const circleRadius = 6;
                const circleX = cardX + cardWidth / 2;
                const circleY = cardY + cardHeight - 90 / 2;
                const reliabilityRating = `${data.reliability}`;
                let reliabilityColor = [0, 0, 0];

                if (reliabilityRating === 'A') {
                    reliabilityColor = [10, 160, 0];
                } else if (reliabilityRating === 'B') {
                    reliabilityColor = [176, 213, 19];
                } else if (reliabilityRating === 'C') {
                    reliabilityColor = [234, 190, 6];
                } else if (reliabilityRating === 'D') {
                    reliabilityColor = [237, 125, 32];
                } else if (reliabilityRating === 'E') {
                    reliabilityColor = [208, 47, 58];
                }
                doc.setFillColor(reliabilityColor[0], reliabilityColor[1], reliabilityColor[2]);
                doc.circle(circleX, circleY + 10, circleRadius, 'F');
                doc.setFontSize(25);
                doc.setTextColor(255, 255, 255);
                doc.text(reliabilityRating, circleX - 3, circleY + 13);

                // Card for maintainability
                doc.setFontSize(8);
                doc.setTextColor(0, 0, 0);
                doc.setFillColor(255, 255, 255);
                doc.rect(cardX + 41, cardY, cardWidth, cardHeight, 'F');
                doc.setTextColor(100, 100, 100);
                doc.text('Maintainability', centerX + 32, cardY + 7);
                doc.text('Code Smells', centerX + 33, cardY + 35);

                const maintainabilityRating = `${data.maintainability}`;

                let maintainabilityColor = [0, 0, 0];

                if (maintainabilityRating === 'A') {
                    maintainabilityColor = [10, 160, 0];
                } else if (maintainabilityRating === 'B') {
                    maintainabilityColor = [176, 213, 19];
                } else if (maintainabilityRating === 'C') {
                    maintainabilityColor = [234, 190, 6];
                } else if (maintainabilityRating === 'D') {
                    maintainabilityColor = [237, 125, 32];
                } else if (maintainabilityRating === 'E') {
                    maintainabilityColor = [208, 47, 58];
                }
                doc.setFillColor(maintainabilityColor[0], maintainabilityColor[1], maintainabilityColor[2]);
                doc.circle(circleX + 41, circleY + 10, circleRadius, 'F');
                doc.setFontSize(25);
                doc.setTextColor(255, 255, 255);
                doc.text(maintainabilityRating, circleX + 38, circleY + 13);

                doc.setFontSize(20);
                doc.setTextColor(0, 0, 0);

                if (data.totalCountCodeSmell.toString().length === 1) {
                    doc.text(data.totalCountCodeSmell.toString(), centerX + 38, cardY + 45);
                } else if (data.totalCountCodeSmell.toString().length === 2) {
                    doc.text(data.totalCountCodeSmell.toString(), centerX + 36, cardY + 45);
                } else if (data.totalCountCodeSmell.toString().length === 3) {
                    doc.text(data.totalCountCodeSmell.toString(), centerX + 35, cardY + 45);
                } else {
                    doc.text(data.totalCountCodeSmell.toString(), centerX + 32, cardY + 45);
                }

                // Card for Duplication
                doc.setFontSize(8);
                doc.setTextColor(0, 0, 0);
                doc.setFillColor(255, 255, 255);
                doc.rect(cardX + 81, cardY, cardWidth, cardHeight, 'F');
                doc.setTextColor(100, 100, 100);
                doc.text('Duplications', centerX + 74, cardY + 7);
                doc.text('Percentage', centerX + 74, cardY + 35);
                const duplicationPercentage = data.duplication;

                if (duplicationPercentage < 3) {
                    doc.setFillColor(10, 160, 0);
                    doc.circle(circleX + 81, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('A', circleX + 78, circleY + 13);
                } else if (duplicationPercentage >= 3 && duplicationPercentage < 5) {
                    doc.setFillColor(176, 213, 19);
                    doc.circle(circleX + 81, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('B', circleX + 78, circleY + 13);
                } else if (duplicationPercentage >= 5 && duplicationPercentage < 10) {
                    doc.setFillColor(234, 190, 6);
                    doc.circle(circleX + 81, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('C', circleX + 78, circleY + 13);
                } else if (duplicationPercentage >= 10 && duplicationPercentage < 20) {
                    doc.setFillColor(237, 125, 32);
                    doc.circle(circleX + 81, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('D', circleX + 78, circleY + 13);
                } else if (duplicationPercentage >= 20) {
                    doc.setFillColor(208, 47, 58);
                    doc.circle(circleX + 81, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('E', circleX + 78, circleY + 13);
                }

                doc.setFontSize(20);
                doc.setTextColor(0, 0, 0);
                doc.text(`${duplicationPercentage}%`, circleX + 73, circleY + 35);

                // Card for comments
                doc.setFontSize(8);
                doc.setTextColor(0, 0, 0);
                doc.setFillColor(255, 255, 255);
                doc.rect(cardX + 122, cardY, cardWidth, cardHeight, 'F');
                doc.setTextColor(100, 100, 100);
                doc.text('Comments', centerX + 115, cardY + 7);
                doc.text('Percentage', centerX + 115, cardY + 35);

                const commentsPercentage = data.comments;
                if (commentsPercentage <= 25) {
                    doc.setFillColor(10, 160, 0);
                    doc.circle(circleX + 123, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('A', circleX + 120, circleY + 13);
                } else if (commentsPercentage > 25 && duplicationPercentage <= 30) {
                    doc.setFillColor(176, 213, 19);
                    doc.circle(circleX + 123, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('B', circleX + 120, circleY + 13);
                } else if (commentsPercentage > 30 && duplicationPercentage <= 35) {
                    doc.setFillColor(234, 190, 6);
                    doc.circle(circleX + 123, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('C', circleX + 120, circleY + 13);
                } else if (commentsPercentage > 35 && duplicationPercentage <= 50) {
                    doc.setFillColor(237, 125, 32);
                    doc.circle(circleX + 123, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('D', circleX + 120, circleY + 13);
                } else if (commentsPercentage > 50) {
                    doc.setFillColor(208, 47, 58);
                    doc.circle(circleX + 123, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('E', circleX + 120, circleY + 13);
                }

                doc.setFontSize(20);
                doc.setTextColor(0, 0, 0);
                doc.text(`${commentsPercentage}%`, circleX + 115, circleY + 35);

                // Card for coverage
                doc.setFontSize(8);
                doc.setTextColor(0, 0, 0);
                doc.setFillColor(255, 255, 255);
                doc.rect(cardX + 163, cardY, cardWidth, cardHeight, 'F');
                doc.setTextColor(100, 100, 100);
                doc.text('Coverage', centerX + 157, cardY + 7);
                doc.text('Percentage', centerX + 156, cardY + 35);

                const coveragePercentage = data.coverage !== undefined ? `${data.coverage}%` : 'NA';

                if (data.coverage >= 80) {
                    doc.setFillColor(10, 160, 0);
                    doc.circle(circleX + 163, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('A', circleX + 160, circleY + 13);
                } else if (data.coverage >= 60 && data.coverage.toString() < 79) {
                    doc.setFillColor(176, 213, 19);
                    doc.circle(circleX + 163, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('B', circleX + 160, circleY + 13);
                } else if (data.coverage >= 35 && data.coverage.toString() < 59) {
                    doc.setFillColor(234, 190, 6);
                    doc.circle(circleX + 163, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('C', circleX + 160, circleY + 13);
                } else if (data.coverage > 0 && data.coverage.toString() < 34) {
                    doc.setFillColor(237, 125, 32);
                    doc.circle(circleX + 163, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('D', circleX + 160, circleY + 13);
                } else if (data.coverage <= 0) {
                    doc.setFillColor(208, 47, 58);
                    doc.circle(circleX + 163, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('E', circleX + 160, circleY + 13);
                } else {
                    doc.setFillColor(0, 0, 0);
                    doc.circle(circleX + 163, circleY + 10, circleRadius, 'F');
                    doc.setFontSize(22);
                    doc.setTextColor(255, 255, 255);
                    doc.text('-', circleX + 162, circleY + 12);
                }

                doc.setFontSize(20);
                doc.setTextColor(0, 0, 0);
                if (coveragePercentage === 'NA') {
                    doc.text(coveragePercentage, circleX + 158, circleY + 35);
                } else {
                    doc.text(coveragePercentage, circleX + 156, circleY + 35);
                }

                // Issues By Severity
                yPosition += 40;
                doc.setFontSize(10);

                doc.setFillColor(255, 255, 255);
                doc.rect(rectX, rectY + 126, rectWidth, rectHeight + 18, 'F');
                doc.setTextColor(0, 0, 0);
                doc.text('Issues by Severity', cardX + 20, 140);
                doc.addImage(Severity, 'PNG', 15, 150, 11, 49);
                doc.setTextColor(100, 100, 100);
                doc.text('Blocker', cardX + 25, 156.5);
                doc.text('Critical', cardX + 25, 166.5);
                doc.text('Major', cardX + 25, 176);
                doc.text('Minor', cardX + 25, 185.5);
                doc.text('Info', cardX + 25, 195);

                if (
                    data.major !== undefined &&
                    data.minor !== undefined &&
                    data.critical !== undefined &&
                    data.blocker !== undefined &&
                    data.bugs !== undefined
                ) {
                    doc.setTextColor(0, 0, 0);
                    doc.text(data.blocker.toString(), cardX + 50, 156.5);
                    doc.text(data.critical.toString(), cardX + 50, 166.5);
                    doc.text(data.major.toString(), cardX + 50, 176);
                    doc.text(data.minor.toString(), cardX + 50, 185.5);
                    doc.text(data.info.toString(), cardX + 50, 195);

                    doc.setFillColor(255, 253, 230);
                    doc.rect(rectX + 70, rectY + 60 + rectHeight + 12, rectWidth - 140, rectHeight, 'F');
                    doc.setFontSize(10);
                    doc.setTextColor(0, 0, 0);
                    doc.text('To be resolved:', rectX + 87, 158);
                    doc.setFontSize(11);
                    doc.setTextColor(208, 47, 58);
                    doc.text('Blocker and Critical Bugs', rectX + 78, 165);

                    doc.setTextColor(51, 102, 187);
                    doc.setFontSize(50);
                    const blocker = data.blocker.toString();
                    const critical = data.critical.toString();
                    const bcBugs = parseInt(blocker, 10) + parseInt(critical, 10);
                    const bugsLink = `https://codeinspect.codehub.cognizantproducts.com/project/issues?resolved=false&severities=CRITICAL,BLOCKER&types=BUG&id=${projectName}`;
                    const bcBugsString = bcBugs.toString();
                    const bcBugsLength = bcBugsString.length;

                    if (bcBugsLength === 1) {
                        doc.textWithLink(bcBugsString, rectX + 96, 188, { url: bugsLink });
                        doc.setDrawColor(51, 102, 187);
                        doc.setLineWidth(0.3);
                        doc.line(rectX + 95, 190, rectX + 95 + bcBugsLength * 11, 190);
                    } else if (bcBugsLength === 2) {
                        doc.textWithLink(bcBugsString, rectX + 90, 188, { url: bugsLink });
                        doc.setDrawColor(51, 102, 187);
                        doc.setLineWidth(0.3);
                        doc.line(rectX + 89, 190, rectX + 89 + bcBugsLength * 11, 190);
                    } else if (bcBugsLength === 3) {
                        doc.textWithLink(bcBugsString, rectX + 84, 188, { url: bugsLink });
                        doc.setDrawColor(51, 102, 187);
                        doc.setLineWidth(0.3);
                        doc.line(rectX + 83, 190, rectX + 83 + bcBugsLength * 11, 190);
                    } else if (bcBugsLength === 4) {
                        doc.textWithLink(bcBugsString, rectX + 81, 188, { url: bugsLink });
                        doc.setDrawColor(51, 102, 187);
                        doc.setLineWidth(0.3);
                        doc.line(rectX + 80, 190, rectX + 80 + bcBugsLength * 11, 190);
                    } else {
                        doc.textWithLink(bcBugsString, rectX + 78, 188, { url: bugsLink });
                        doc.setDrawColor(51, 102, 187);
                        doc.setLineWidth(0.3);
                        doc.line(rectX + 77, 190, rectX + 77 + bcBugsLength * 11, 190);
                    }
                }
                doc.setFontSize(10);
                doc.setFillColor(255, 255, 255);
                doc.setTextColor(100, 100, 100);
                doc.rect(rectX, rectY + 128 + rectHeight + 20, rectWidth, rectHeight + 14, 'F');

                doc.setTextColor(0, 0, 0);
                doc.text('Language Breakdown', cardX + 148, 140);
                doc.setTextColor(0, 0, 0);

                const languageData = data.language;
                const languages = languageData.split(';');
                doc.setFontSize(10);
                doc.setTextColor(100, 100, 100);
                let yPos = 140;
                doc.text('Lines of Code', cardX + 170, yPos + 8);
                languages.forEach((language: any) => {
                    const [label, count] = language.split('=');
                    doc.setTextColor(100, 100, 100);
                    doc.setFontSize(7);
                    doc.text(label.toUpperCase(), cardX + 188, yPos + 16);
                    doc.setTextColor(0, 0, 0);
                    doc.setFontSize(11);
                    doc.text(count, cardX + 170, yPos + 16);
                    yPos += 7;
                });
                doc.setFontSize(10);
                doc.setTextColor(100, 100, 100);
                doc.text('Size', cardX + 145, 148);
                doc.setFillColor(41, 123, 174);
                doc.circle(circleX + 130, circleY + 85, 8, 'F');

                if (data.lines < 1000) {
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('XS', 148, 170);
                } else if (data.lines >= 1000 && data.lines < 10000) {
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('S', 151, 170);
                } else if (data.lines >= 10000 && data.lines < 100000) {
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('M', 150, 170);
                } else if (data.lines >= 100000 && data.lines < 500000) {
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('L', 151, 170);
                } else if (data.lines >= 500000) {
                    doc.setFontSize(25);
                    doc.setTextColor(255, 255, 255);
                    doc.text('XL', 149, 170);
                }
                doc.setFontSize(10);
                doc.setTextColor(0, 0, 255);
                const ipreadyQM =
                    'https://cognizantonline.sharepoint.com/sites/CorporateFunctions/SitePages/IP-Ready-Quality-Metrics.aspx';
                doc.textWithLink('IP Ready Quality metrics', 86, 225, { url: ipreadyQM });

                doc.setTextColor(100, 100, 100);
                doc.setFontSize(9);
                doc.text('Reliability Bug', 15, 234);
                doc.setFontSize(9);
                doc.setTextColor(10, 160, 0);
                doc.text('A = No Bugs', 14, 250);
                doc.setTextColor(176, 213, 19);
                doc.text('B = At Least 1 Minor Bug', 14, 257);
                doc.setTextColor(234, 190, 6);
                doc.text('C = At Least 1 Major Bug', 14, 264);
                doc.setTextColor(237, 125, 32);
                doc.text('D = At Least 1 Critical Bug', 14, 271);
                doc.setTextColor(208, 47, 58);
                doc.text('E = At Least 1 BlockerBug', 14, 278);

                // Maintainability
                doc.setTextColor(100, 100, 100);
                doc.text('Maintainability (SCALE Rating)', 58, 234);
                doc.setFontSize(9);
                doc.setTextColor(10, 160, 0);
                doc.text('A = 0% upto 5%', 68, 250);
                doc.setTextColor(176, 213, 19);
                doc.text('B = > 5% upto 10%', 68, 257);
                doc.setTextColor(234, 190, 6);
                doc.text('C = > 10% upto 20%', 68, 264);
                doc.setTextColor(237, 125, 32);
                doc.text('D = > 20% upto 50%', 68, 271);
                doc.setTextColor(208, 47, 58);
                doc.text('E = Over 50%', 68, 278);

                doc.setTextColor(100, 100, 100);
                doc.text('Technical Debt Ratio', 65, 239);

                doc.setTextColor(100, 100, 100);
                doc.text('Duplications %', 110, 234);
                doc.setFontSize(9);
                doc.setTextColor(10, 160, 0);
                doc.text('A = < 3%', 114, 250);
                doc.setTextColor(176, 213, 19);
                doc.text('B = < 5%', 114, 257);
                doc.setTextColor(234, 190, 6);
                doc.text('C = < 10%', 114, 264);
                doc.setTextColor(237, 125, 32);
                doc.text('D = < 20%', 114, 271);
                doc.setTextColor(208, 47, 58);
                doc.text('E = >= 20%', 114, 278);

                doc.setTextColor(100, 100, 100);
                doc.text('Comments %', 140, 234);
                doc.setFontSize(9);
                doc.setTextColor(10, 160, 0);
                doc.text('A = 0% upto 25%', 138, 250);
                doc.setTextColor(176, 213, 19);
                doc.text('B = >25% upto 30%', 138, 257);
                doc.setTextColor(234, 190, 6);
                doc.text('C = >30% upto 35%', 138, 264);
                doc.setTextColor(237, 125, 32);
                doc.text('D = >35% upto 50%', 138, 271);
                doc.setTextColor(208, 47, 58);
                doc.text('E = Over 50%', 138, 278);

                doc.setTextColor(100, 100, 100);
                doc.text('Unit Test Coverage', 172, 234);
                doc.setFontSize(9);
                doc.setTextColor(10, 160, 0);
                doc.text('A = >= 80%', 176, 250);
                doc.setTextColor(176, 213, 19);
                doc.text('B = >= 60%', 176, 257);
                doc.setTextColor(234, 190, 6);
                doc.text('C = >= 35%', 176, 264);
                doc.setTextColor(237, 125, 32);
                doc.text('D = > 0%', 176, 271);
                doc.setTextColor(208, 47, 58);
                doc.text('E = No Tests', 176, 278);
                doc.setTextColor(0, 0, 0);
                doc.text('NA = Undefined', 176, 285);
            });
            doc.save('SonarReport.pdf');
        }
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Card style={{ maxWidth: '800px', margin: '100px 0', textAlign: 'center' }}>
                <CardContent>
                    <img
                        src={Sonarqube}
                        alt="Logo"
                        height="50px"
                        width="180px"
                        style={{ margin: '0 auto', marginBottom: '15px' }}
                    />
                    <Autocomplete
                        multiple
                        id="project-keys"
                        options={filteredProjectKeys}
                        getOptionLabel={(option) => option}
                        value={selectedProjectKeys}
                        onChange={(_, newValue) => {
                            setSelectedProjectKeys(newValue);
                        }}
                        style={{ width: '600px', borderRadius: '40px', margin: '0 auto' }}
                        renderInput={(params) => (
                            <TextField {...params} label="Search here using Sonar Project Key..." variant="outlined" />
                        )}
                        onInputChange={(_, newInputValue) => {
                            handleInputChange({
                                target: { value: newInputValue },
                            } as React.ChangeEvent<HTMLInputElement>);
                        }}
                    />
                </CardContent>
                <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
                    <CardActions>
                        {sonarData === null ? (
                            <Button variant="contained" type="submit" onClick={fetchingData}>
                                Generate Report
                            </Button>
                        ) : (
                            <Button variant="contained" type="submit" color="success" onClick={handleDownloadPDF}>
                                Download PDF
                            </Button>
                        )}
                        <Button variant="contained" type="submit" color="error" value="Clear" onClick={clearData}>
                            Clear
                        </Button>
                    </CardActions>
                </div>
            </Card>
        </div>
    );
};
export default SonarReport;
