import { getRequestHeader } from './Keycloak';

export const fetchData = async (URL: string, callback: any) => {
    const header = getRequestHeader();
    return await fetch(URL, {
        method: 'GET',
        headers: header,
    })
        .then((response) => {
            return response.json();
        })
        .then((responseData) => {
            callback(responseData);
        })
        .catch((err) => {
            // eslint-disable-next-line no-empty
        });
};

export const postData = async (URL: string, data: any, callback: any) => {
    const header = getRequestHeader();
    return await fetch(URL, {
        method: 'POST',
        headers: {
            ...header,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((response) => {
            return response.json();
        })
        .then((responseData) => {
            callback(responseData);
        })
        .catch((err) => {
            // eslint-disable-next-line no-empty
        });
};

export const fetchTableData = async (URL: string, callback: any) => {
    const header = getRequestHeader();
    return await fetch(URL, {
        method: 'GET',
        headers: header,
    })
        .then(async (response) => {
            const count = response.headers.get('x-total-count');
            const data = await response.json();
            return {
                data,
                count,
            };
        })
        .then((responseData) => {
            callback(responseData.data, responseData.count);
        })
        .catch((err) => {
            // eslint-disable-next-line no-empty
        });
};
