import { useCallback, useEffect, useState } from 'react';
import { Paper, Card, CardContent, Typography, Skeleton } from '@mui/material';
import { getRequestHeader } from '../../../utils/Keycloak';
import GithubRepoTable from '../Container/GithubRepoTable';
import RefreshMatrix from './RefreshMatrix';
import axios from 'axios';
const API_URL = process.env.REACT_APP_URL;

interface ResponseData {
    notLinkedSizeZeroCount: number;
    notLinkedSizeZeroArchivedCount: number;
    notLinkedUrlCount: number;
    notLinkedArchivedCount: number;
    notLinkedSizedUrlCount: number;
    totalNotLinkedArchivedCount: number;

    chSizeZeroLinkedRepoCount: number;
    chArchivedsizeZeroLinkedRepoCount: number;
    chLinkedUrlRepoCount: number;
    chArchivedRepoCount: number;
    chRepoCount: number;
    chArchivedTotalCount: number;

    qaRepoLowerEnvCount: number;
    stageRepoLowerEnvCount: number;
    localRepoLowerEnvCount: number;
    devRepoLowerEnvCount: number;

    archivedQaRepoLowerEnvCount: number;
    archivedStageRepoLowerEnvCount: number;
    archivedLocalRepoLowerEnvCount: number;
    archivedDevRepoLowerEnvCount: number;

    quickstartPrototypeCount: number;
    quickstartBlueprintCount: number;
    quickstartArchCounts: number;
    quickstartBlueprintCoundata: number;
    quickstartDevCount: number;
    quickstartLocalCountdata: number;
    quickstartProdCount: number;
    quickstartPrototypeCoudata: number;
    quickstartQaCount: number;
    quickstartStageCount: number;
    quickstartBlueprintArchivedCount: number;
    quickstartDevArchivedCount: number;
    quickstartLocalArchivedCount: number;
    quickstartProdArchivedCount: number;
    quickstartPrototypeArchivedCount: number;
    quickstartQaArchivedCount: number;
    quickstartStagArchivedeCount: number;
    quickstartLocalCount: number;
    quickstartArchArchivedeCount: number;
    quickstartTotalRepoCount: number;
    quickstartarchivedTotalRepoCount: number;
}
export default function GithubCard(props: any) {
    const [githubCardData, SetGithubCardData] = useState<ResponseData | null>(null);
    const fetchGithubData = useCallback(async (doHardRefresh: boolean) => {
        const gitHubURL = API_URL + `/api/v1/metric/github?refresh=${doHardRefresh}`;
        const headers = getRequestHeader();
        const response = await axios.get(gitHubURL, { headers });
        SetGithubCardData(response.data);
    }, []);
    const reinitializeData = async () => {
        SetGithubCardData(null);
        return Promise.all([/*fetchGitlabData(true),*/ fetchGithubData(true)]);
    };

    const repoCounts = [
        {
            environment: 'Quickstart Architecture',
            color: 'black',
            count: githubCardData?.quickstartArchCounts,
            archived: githubCardData?.quickstartArchArchivedeCount,
        },
        {
            environment: 'Quickstart BluePrint',
            color: 'black',
            count: githubCardData?.quickstartBlueprintCount,
            archived: githubCardData?.quickstartBlueprintArchivedCount,
        },
        {
            environment: 'Quickstart Prototype',
            color: 'black',
            count: githubCardData?.quickstartPrototypeCount,
            archived: githubCardData?.quickstartPrototypeArchivedCount,
        },
        {
            environment: 'Quickstart Prod',
            color: 'green',
            count: githubCardData?.quickstartProdCount,
            archived: githubCardData?.quickstartProdArchivedCount,
        },
        {
            environment: 'Quickstart Local',
            color: 'red',
            count: githubCardData?.quickstartLocalCount,
            archived: githubCardData?.quickstartLocalArchivedCount,
        },
        {
            environment: 'Quickstart Dev',
            color: 'red',
            count: githubCardData?.quickstartDevCount,
            archived: githubCardData?.quickstartDevArchivedCount,
        },
        {
            environment: 'Quickstart QA',
            color: 'red',
            count: githubCardData?.quickstartQaCount,
            archived: githubCardData?.quickstartQaArchivedCount,
        },
        {
            environment: 'Quickstart Stage',
            color: 'red',
            count: githubCardData?.quickstartStageCount,
            archived: githubCardData?.quickstartStagArchivedeCount,
        },
        {
            environment: 'Total Count',
            color: 'black',
            count: githubCardData?.quickstartTotalRepoCount,
            archived: githubCardData?.quickstartarchivedTotalRepoCount,
        },
    ];

    const codehubRepoCounts = [
        {
            environment: 'Linked in CodeHub',
            color: 'green',
            count: githubCardData?.chLinkedUrlRepoCount,
            archived: githubCardData?.chArchivedRepoCount,
        },
        {
            environment: 'Linked but Empty',
            color: 'green',
            count: githubCardData?.chSizeZeroLinkedRepoCount,
            archived: githubCardData?.chArchivedsizeZeroLinkedRepoCount,
        },
        {
            environment: 'CodeHub Local',
            color: 'red',
            count: githubCardData?.localRepoLowerEnvCount,
            archived: githubCardData?.archivedDevRepoLowerEnvCount,
        },
        {
            environment: 'Codehub Development',
            color: 'red',
            count: githubCardData?.devRepoLowerEnvCount,
            archived: githubCardData?.archivedLocalRepoLowerEnvCount,
        },
        {
            environment: 'CodeHub QA',
            color: 'red',
            count: githubCardData?.qaRepoLowerEnvCount,
            archived: githubCardData?.archivedQaRepoLowerEnvCount,
        },
        {
            environment: 'Codehub Stage',
            color: 'amber',
            count: githubCardData?.stageRepoLowerEnvCount,
            archived: githubCardData?.archivedStageRepoLowerEnvCount,
        },
        {
            environment: 'Total',
            color: 'green',
            count: githubCardData?.chRepoCount,
            archived: githubCardData?.chArchivedTotalCount,
        },
    ];
    const notLinkedRepoCounts = [
        {
            environment: 'Contains Code',
            color: 'amber',
            count: githubCardData?.notLinkedSizedUrlCount,
            archived: githubCardData?.notLinkedArchivedCount,
        },
        {
            environment: 'Empty Repos',
            color: 'amber',
            count: githubCardData?.notLinkedSizeZeroCount,
            archived: githubCardData?.notLinkedSizeZeroArchivedCount,
        },
        {
            environment: 'Total',
            color: 'red',
            count: githubCardData?.notLinkedSizedUrlCount,
            archived: githubCardData?.totalNotLinkedArchivedCount,
        },
    ];

    useEffect(() => {
        fetchGithubData(false);
    }, [fetchGithubData]);

    return (
        <>
            <RefreshMatrix onRefresh={reinitializeData} />
            <Paper elevation={1}>
                <Card variant="outlined" sx={{ background: props.color, color: '#000048', textAlign: 'center' }}>
                    <CardContent>
                        <Typography color="text.secondary">{props.type}</Typography>
                        <Typography variant="h2" component="div">
                            {props.value === null ? (
                                <Skeleton
                                    variant="rectangular"
                                    width={120}
                                    height={60}
                                    sx={{
                                        margin: ' 10px auto 10px auto',
                                        display: 'block',
                                    }}
                                />
                            ) : (
                                props.value
                            )}
                        </Typography>
                        <Typography sx={{ fontSize: 15 }} color="#000048" gutterBottom>
                            GitHub Repositories
                        </Typography>
                    </CardContent>
                    <div>
                        {props.type === 'CodeHub' ? (
                            <GithubRepoTable repoCounts={codehubRepoCounts} tableHeading="CodeHub Summary" />
                        ) : props.type === 'QuickStart' ? (
                            <GithubRepoTable repoCounts={repoCounts} tableHeading="QuickStart Summary" />
                        ) : (
                            <GithubRepoTable repoCounts={notLinkedRepoCounts} tableHeading="Not Linked Summary" />
                        )}
                    </div>
                </Card>
            </Paper>
        </>
    );
}
