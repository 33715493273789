import * as XLSX from '../../node_modules/xlsx';

function readExcelSheets(file: any, sheetName: string): Promise<unknown> {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.readAsArrayBuffer(file);

        fileReader.onload = (e: any) => {
            const bufferArray = e.target.result;
            const wb = XLSX.read(bufferArray, { type: 'buffer' });
            const wsname = wb.SheetNames[wb.SheetNames.indexOf(sheetName)];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            resolve(data);
        };

        fileReader.onerror = (error) => {
            reject(error);
        };
    });
}

export const xlsxToJson = (file: any, sheetName: string, callback: (param: any) => void) => {
    readExcelSheets(file, sheetName)
        .then((res) => {
            callback(res);
        })
        .catch((error) => {
            // eslint-disable-next-line no-empty
        });
};
