import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useState } from 'react';

const Input = styled('input')({
    display: 'none',
});

export default function FileUploadButton(props: any) {
    const [filename, setFilename] = useState('upload');

    const handleUpload = (event: any) => {
        if (event.target.files.item(0) != null) setFilename(event.target.files.item(0).name);
        props.onChange(event);
    };

    return (
        <label htmlFor="contained-button-file">
            <Input
                accept={props.accept.join(',')}
                id="contained-button-file"
                type="file"
                name="usersfile"
                onChange={(e) => handleUpload(e)}
            />
            <Button variant="contained" style={props.style} component="span">
                {filename}
            </Button>
        </label>
    );
}
