import * as React from 'react';
import { Box, Button, Fade, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import GitHubIcon from '@mui/icons-material/GitHub';
import { ENABLE_CIRCLECI_TEMPLATE_URL, GITHUB_TEMPLATE_URL } from '../../../utils/constants';
import CircleCiImage from '../../../assets/images/circleci_black.svg';

export default function FadeMenu(props: any) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const Download = (url: any) => {
        const link = document.createElement('a');
        link.download = `download.xlsx`;
        link.href = url;
        link.click();
    };
    return (
        <>
            <div>
                <Tooltip title="Templates">
                    <Button
                        id="fade-button"
                        aria-controls={open ? 'fade-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                        sx={{ color: props.color }}
                    >
                        <Box sx={{ display: { xs: 'none', md: 'block' } }}>Templates</Box>
                        <Box style={{ color: props.color, padding: '0 3px', paddingTop: '7px' }}>
                            <ArrowDropDownIcon />
                        </Box>
                    </Button>
                </Tooltip>
                <Menu
                    id="fade-menu"
                    MenuListProps={{
                        'aria-labelledby': 'fade-button',
                    }}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Fade}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            Download(GITHUB_TEMPLATE_URL);
                        }}
                    >
                        <IconButton>
                            <GitHubIcon style={{ color: '#000000' }} />
                        </IconButton>
                        GitHub Template
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            Download(ENABLE_CIRCLECI_TEMPLATE_URL);
                        }}
                    >
                        <IconButton>
                            <img src={CircleCiImage} alt="CircleCi Icon" />
                        </IconButton>
                        CircleCi Template
                    </MenuItem>
                </Menu>
            </div>
        </>
    );
}
